import { Fetch as FetchRibbons } from 'core/ribbons';
import { combineEpics } from 'redux-most';
import { messages as wlMessages, selectors as wlSelectors } from '@ux/whitelabel';
import { createEpic, createStructuredSelector } from '@ux/fabric';
import * as r from 'ramda';
import * as rA from 'ramda-adjunct';
import browserCookies from 'browser-cookies';

import {
  FETCH,
  Fetch,
  setVisibility,
  fetch,
} from 'application/signals/ribbons';
import {
  RIBBON_FETCHED,
  fetching,
  ribbonsPrefetched,
  ribbonFetched,
  ribbonsFetched,
  ribbonsFailed,
  pageLanguageFetched,
} from 'domain/messages/ribbons';
import {
  Type,
  FetchType,
} from 'domain/constants/ribbons';
import { getPath, isRibbonsPage } from 'domain/selectors/common';

const getLocales = (state: any, cookies: any) => {
  const brandConfig = wlSelectors.getBrandConfig(state);
  const language = cookies.get('ux-language-preferred');

  return [ language ]
    .concat(brandConfig.languages)
    .filter((v, i, arr) => arr.indexOf(v) === i);
};

// retry to fetch the page again before showing the error state
const refetchLimit = 1;

const onRibbonsFailed = (attempts: number) => (err: any) => {
  if (attempts < refetchLimit) {
    // @ts-ignore
    return fetch({
      attempts: attempts + 1,
      canFetchFromCache: false,
    });
  }
  return ribbonsFailed(err);
};

const initialFetchEpic = () => createEpic({
  signal: wlMessages.APP_CONFIG_SUCCESS,
  selector: createStructuredSelector({ isRibbonsPage: isRibbonsPage }),
  filter: (action, state: any) => state.isRibbonsPage,
  onSuccess: r.always(fetch()),
});

const fetchEpic = (fetch: FetchRibbons, cookies = browserCookies) => createEpic({
  signal: FETCH,
  pending: () => fetching(),
  process: (action: Fetch, state: any) => {
    const locale = getLocales(state, cookies);
    const path = getPath(state);
    const { payload: { canFetchFromCache } } = action;
    return fetch(locale, path, canFetchFromCache);
  },
  onSuccess: (data, action) => r.cond([
    [
      r.propEq('type', FetchType.RIBBONS_PREFETCHED),
      r.pipe(
        r.prop('payload'),
        ribbonsPrefetched,
      ),
    ],
    [
      r.propEq('type', FetchType.PAGE_LANGUAGE),
      r.pipe(
        r.prop('payload'),
        pageLanguageFetched,
      ),
    ],
    [
      r.propEq('type', FetchType.RIBBON_FETCHED),
      r.pipe(
        r.prop('payload'),
        ribbonFetched,
      ),
    ],
    [
      r.propEq('type', FetchType.RIBBONS_FETCHED),
      r.always(ribbonsFetched()),
    ],
    [
      r.propEq('type', FetchType.ERRORED),
      r.pipe(
        r.prop('payload'),
        onRibbonsFailed(action.payload.attempts),
      ),
    ],
    [
      r.T,
      r.identity,
    ],
  ])(data),
  onFailure: (err, action) => {
    const { payload: { attempts } } = action;
    return onRibbonsFailed(attempts)(err);
  },
  join: true,
});

const initialVisibilityEpic = () => createEpic({
  signal: RIBBON_FETCHED,
  filter: r.pipe(
    r.prop('payload'),
    r.both(
      r.propEq('type', Type.META),
      r.pathSatisfies(
        rA.isNotNil,
        [ 'props', 'initialVisibilitySets' ],
      ),
    ),
  ),
  onSuccess: r.pipe(
    r.path([ 'payload', 'props', 'initialVisibilitySets' ]),
    setVisibility,
  ),
});

// eslint-disable-next-line no-underscore-dangle
export const __test__ = {
  fetchEpic,
  initialVisibilityEpic,
};

export default (fetch: FetchRibbons) => combineEpics([
  initialFetchEpic(),
  fetchEpic(fetch),
  initialVisibilityEpic(),
]);