import { useMemo } from 'react';
import { useHeaderHeight } from '@ux/bookends';
import {
  getRibbons,
  splitRibbons,
} from 'domain/selectors/ribbons';
import { useSelector } from 'react-redux';
import RibbonRaid from './RibbonRaid';
import { puree } from '@ux/pure-enhance';
import { withProps } from '@team-griffin/rehook';
import { useBreakpoints } from '@ux/responsive';
import browserCookies from 'browser-cookies';
import { getExperiencesConfig } from 'domain/selectors/ribbons/experiences';
import { EXPERIMENTS_COOKIE } from 'domain/constants/experiences';

interface OuterProps {}

const enhance = puree<OuterProps>('ConnectedRibbonRaid')(
  withProps(() => {
    const {
      isMinSm,
      isMinMd,
    } = useBreakpoints();

    const experimentationCookie = browserCookies.get(EXPERIMENTS_COOKIE);
    const cookieExperiences = getExperiencesConfig(experimentationCookie);
    const allRibbons = useSelector(getRibbons(cookieExperiences?.experiences ?? []));

    const leadingHeightOffset = useHeaderHeight();
    const [ leadingRibbons, ribbons ] = useMemo(() => splitRibbons({
      ribbons: allRibbons,
      isMinSm: isMinSm,
      isMinMd: isMinMd,
    }), [ allRibbons, isMinSm, isMinMd ]);

    return {
      allRibbons,
      leadingRibbons,
      ribbons,
      leadingHeightOffset,
    };
  }),
);

// @ts-ignore
export default enhance(RibbonRaid);
