import { Navigate } from 'core/navigation';

import { createEpic } from '@ux/fabric';

import {
  VIEW_BASKET,
  ViewBasket,
} from 'application/signals/ribbons/domain-search';
import { getOrderProcessUrl } from 'domain/selectors/basket';

const viewBasketEpic = (
  navigate: Navigate,
) => createEpic({
  signal: VIEW_BASKET,
  process: (action: ViewBasket, state: Object) => {
    navigate(
      getOrderProcessUrl(state),
    );
  },
});

export default viewBasketEpic;
