import {
  json,
  jsonRpc,
  middleware,
  xray,
  fetch,
  bodyOnly,
} from '@ux/drivers';
import { middleware as cradle } from '@ux/cradle';
import * as r from 'ramda';

export default () => r.compose(
  middleware(),
  bodyOnly(),
  cradle(),
  jsonRpc(),
  json(),
  xray(),
)(fetch);