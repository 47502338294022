import jpex from 'jpex';
import { NotifyCradle } from '@ux/cradle';
import createInfrastructure from 'infrastructure/composite';


export default () => {
  const infrastructure = createInfrastructure();
  jpex.constant<Window>(window);
  jpex.constant<NotifyCradle>(infrastructure.notifyCradle);
  return infrastructure;
};
