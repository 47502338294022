import browserCookies from 'browser-cookies';
import { createEpic } from '@ux/fabric';
import { selectors as product } from '@ux/product';
import { PRODUCT_ADD_TO_BASKET } from 'application/signals/cta';

const {
  getPrice,
} = product;

const basketEpic = (
  ecommerce: Function,
) => createEpic({
  signal: PRODUCT_ADD_TO_BASKET,
  selector: () => {
    return {
      locale: browserCookies.get('ux-language-preferred'),
    };
  },
  process: (action) => {
    const {
      payload: {
        list,
        product,
      },
    } = action;

    if (!product) {
      return;
    }
    const id = product.id;
    const name = product.name;

    // in case we ever get a malformed product, getPrice would throw an error
    try {
      const price = getPrice({
        product,
        includeTax: false,
      });

      ecommerce([
        [
          'addProduct',
          {
            id: `${id}`,
            name,
            price,
          },
        ],
        [
          'setAction',
          'add',
          {
            list,
          },
        ],
      ]);
    } catch (error) {
      console.error(error);
    }
  },
});

export default basketEpic;
