import * as _scout from './scout';

export default (
  document: Document,
) => {
  return {
    isPageLoaderDestroyed: _scout.isPageLoaderDestroyed(document),
    notifyScout: _scout.notifyScout(document),
  };
};
