import { createViewAggregateReducer as createReducer } from '@ux/view-aggregate';
import {
  View,
} from 'core/configurator';

export type State = {
  view: View,
};

export default createReducer('configurator');
