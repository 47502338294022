import * as most from 'most';
import { GetQuery } from 'core/navigation';
import { NDJSON_START, NDJSON_END } from '@ux/drivers';
import { ProcessPageFetched } from './processPageFetched';
import { ProcessRibbonFinished } from './processRibbonFinished';

import * as r from 'ramda';
import * as qs from 'qs';
import { FetchType } from 'domain/constants/ribbons';

const checkArgs = r.pipe(
  r.reject(r.isNil),
  r.evolve({
    pageId: (pageId) => parseInt(pageId, 10),
  }),
);

const dispatchCriticalPath = r.once((dispatchGAEvent, now) => {
  dispatchGAEvent(
    'timing',
    'USF Critical Path',
    'start -> ribbons finished',
    Math.round(now),
  );
});

export type FetchFromApi = (locales: string[], path: string) => most.Stream<any>;

const fetchFromApi = (
  ndjson: Function,
  getQuery: GetQuery,
  processPageFetched: ProcessPageFetched,
  processRibbonFinished: ProcessRibbonFinished,
  dispatchGAEvent: Function,
): FetchFromApi => (locales, path) => {
  const {
    page_id: pageId,
    revision,
  } = getQuery();

  const args = checkArgs({
    path,
    revision,
    pageId,
    locales,
  });
  const url = `/api/app/v1/queries/fetch-page?${qs.stringify(args)}`;

  const requestRttStart = performance.now();
  let analyzeTimings = true;

  return ndjson({ url }).chain(({
    type,
    payload,
  }: {
    type: any,
    payload: any,
  }) => {
    return r.cond([
      [
        r.always(r.equals(type, NDJSON_START)),
        (response) => {
          // if the api sets an x-pex-no-cache header it means it's a dev with a no-cache cookie
          // so we don't want to bother sending any timing data to GA as it won't be representitive
          if (response.headers.get('x-pex-no-cache')) {
            analyzeTimings = false;
          }
          return most.empty();
        },
      ],
      [
        r.always(r.equals(type, 'PAGE_FETCHED')),
        processPageFetched(path, processRibbonFinished),
      ],
      [
        r.always(r.equals(type, 'RIBBON_FINISHED')),
        processRibbonFinished(path),
      ],
      [
        r.always(r.equals(type, 'ERROR')),
        r.pipe(
          r.tap(r.invoker(1, 'error')(r.__, console)),
          r.objOf('payload'),
          r.assoc('type', FetchType.ERRORED),
          most.of,
        ),
      ],
      [
        r.always(r.equals(type, NDJSON_END)),
        r.pipe(
          r.tap(() => {
            if (!analyzeTimings) {
              return;
            }
            const now = performance.now();
            const rttDiff = now - requestRttStart;

            dispatchCriticalPath(dispatchGAEvent, now);

            dispatchGAEvent(
              'timing',
              'USF RTT',
              'fetch page',
              Math.round(rttDiff),
            );
          }),
          r.always(most.of({
            type: FetchType.RIBBONS_FETCHED,
            payload: {},
          })),
        ),
      ],
    ])(payload);
  });
};

export default fetchFromApi;
