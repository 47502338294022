import { createAction } from 'redux-actions';

const prefix = '@@usf/cta/S_';
export const PRODUCT_CLICKED = `${prefix}PRODUCT_CLICKED`;
export const PROMOTION_CLICKED = `${prefix}PROMOTION_CLICKED`;
export const PRODUCT_ADD_TO_BASKET = `${prefix}PRODUCT_ADD_TO_BASKET`;

export const productClicked = createAction(PRODUCT_CLICKED);
export const promotionClicked = createAction(PROMOTION_CLICKED);
export const productAddToBasket = createAction(PRODUCT_ADD_TO_BASKET);

export type ProductAddToBasket = ReturnType<typeof productAddToBasket>;