import { createReducer } from 'redux-create-reducer';
import * as r from 'ramda';

import {
  FETCHING,
  RIBBONS_PREFETCHED,
  RIBBON_FETCHED,
  RIBBONS_FAILED,
  RIBBONS_FETCHED,
  SET_VISIBILITY,
  PAGE_LANGUAGE_FETCHED,
  RibbonsPreFetched,
  RibbonFetched,
  SetVisibility,
  PageLanguageFetched,
} from 'domain/messages/ribbons';
import {
  FETCHED as CONFIGURATOR_FETCHED,
} from 'domain/messages/configurator';

export type State = {
  initialized: boolean,
  fetched: boolean,
  ribbons: Array<{
    id: string,
    type: string,
    ready: boolean,
    props: {
      [key: string]: any,
    },
  }>,
  language: {
    language: string,
    languages: {
      [key: string]: string,
    },
    path: string,
    status: number,
  },
  visibilitySet: Object,
  pageLoadFailed: boolean,
};

export const initialState: State = {
  initialized: false,
  fetched: false,
  ribbons: [],
  language: {
    language: '',
    languages: {},
    path: '/',
    status: 200,
  },
  visibilitySet: {},
  pageLoadFailed: false,
};

const reducer = createReducer<State, any>(initialState, {
  [FETCHING]: (state) => ({
    ...state,
    ribbons: [],
    initialized: false,
    pageLoadFailed: false,
    fetched: false,
  }),

  [RIBBONS_PREFETCHED]: (state, action: RibbonsPreFetched) => ({
    ...state,
    ribbons: action.payload,
    initialized: true,
  }),

  [RIBBON_FETCHED]: (state: State, action: RibbonFetched) => {
    const index = r.findIndex(r.propEq('id', action.payload.id), state.ribbons);

    return r.over(
      r.lensPath([ 'ribbons', index ]),
      r.pipe(
        r.assoc('props', action.payload.props),
        r.assoc('ready', true),
      ),
    )(state);
  },

  [RIBBONS_FETCHED]: r.assoc('fetched', true),

  [RIBBONS_FAILED]: (state) => ({
    ...state,
    initialized: true,
    pageLoadFailed: true,
  }),

  [SET_VISIBILITY]: (state, action: SetVisibility) => r.pipe(
    r.over(
      r.lensProp('visibilitySet'),
      r.merge(r.__, r.fromPairs(action.payload)),
    ),
  )(state) as State,

  [PAGE_LANGUAGE_FETCHED]: (state: State, action: PageLanguageFetched) => r.assoc(
    'language',
    action.payload,
    state,
  ),
  [CONFIGURATOR_FETCHED]: (state) => r.assoc('language', {}, state),
});

export default reducer;
