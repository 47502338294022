import React, { useMemo } from 'react';
import { RibbonRaidType } from './RibbonRaid';
import shrinkLayoutHolistic from '../ribbons/holistics/shrinkLayoutHolistic';
import duoLayoutHolistic from '../ribbons/holistics/duoLayoutHolistic';
import Ribbon from './Ribbon';
import { puree, GetInnerProps } from '@ux/pure-enhance';
import { withProps } from '@team-griffin/rehook';
import { useBreakpoints } from '@ux/responsive';

interface OuterProps {
  offset: number,
  ribbons: RibbonRaidType[],
  allRibbons: RibbonRaidType[],
}

const enhance = puree<OuterProps>('RibbonSet')(
  withProps(({
    allRibbons,
    offset,
    ribbons: baseRibbons,
  }) => {
    const { isMinMd } = useBreakpoints();

    const ribbons = useMemo(
      () => baseRibbons.map((ribbon, index) => {
        const shrunkRibbon = shrinkLayoutHolistic(
          ribbon,
          offset + index,
          allRibbons,
        );
        const duoRibbon = duoLayoutHolistic(
          shrunkRibbon,
          index,
          baseRibbons,
          isMinMd,
        );
        return duoRibbon;
      }),
      [
        baseRibbons,
        offset,
        allRibbons,
        isMinMd,
      ],
    );

    return { ribbons };
  }),
);

type InnerProps = GetInnerProps<typeof enhance>;

declare const index: number;
declare const ribbon: RibbonRaidType;

const PureRibbonSet = ({
  ribbons,
}: InnerProps) => (
  <>
    <For
      each="ribbon"
      of={ribbons}
      index="index"
    >
      <Ribbon
        key={ribbon.id}
        index={index}
        ribbon={ribbon}
        ribbons={ribbons}
      />
    </For>
  </>
);

export default enhance(PureRibbonSet);
