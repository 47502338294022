import { createEpic } from '@ux/fabric';
import { select, combineEpics } from 'redux-most';
import * as most from 'most';
import * as mostc from 'mostc';
import * as r from 'ramda';
import { signals as rkSignals } from '@ux/reactive-knowledge';

import {
  PURCHASE,
  PURCHASE_BUNDLE,
  TRANSFER,
  BACKORDER,
  Purchase,
  PurchaseBundle,
  Transfer,
  Backorder,
} from 'application/signals/ribbons/domain-search';
import {
  addDomainToBasket,
} from 'application/signals/basket';
import {
  ADDED_TO_BASKET,
  ADD_TO_BASKET_FAILED,
} from 'domain/messages/basket';

const purchaseEpic = () => createEpic({
  signal: PURCHASE,
  onSuccess: (action: Purchase) => addDomainToBasket(
    action.payload.fqdn,
    'DOMAINS',
    false,
    action.payload.resolve,
    action.payload.reject,
  ),
});

const purchaseBundleEpic = () => createEpic({
  signal: PURCHASE_BUNDLE,
  onSuccess: (x, action: PurchaseBundle, state, actions$) => {
    const fqdns = action.payload.fqdns;
    // emit an addDomainToBasket signal for each fqdn in the bundle
    const addToBasket$ = r.pipe(
      r.map((fqdn) => addDomainToBasket(fqdn, 'DOMAINS', true)),
      most.from,
    )(fqdns);

    const finished$ = r.pipe(
      // we need to wait for the success message for every fqdn
      // before we can say we've added the whole bundle
      r.map((fqdn) => r.pipe(
        select(ADDED_TO_BASKET),
        mostc.filter(r.pathEq([ 'payload', 'fqdn' ], fqdn)),
      )(actions$)),
      // once all of the items are added, we can resolve the linked promise
      (actions$) => most.combineArray(() => {
        action.payload.resolve();
      }, actions$),
      mostc.map(
        r.always(
          rkSignals.add({
            type: 'SMIRK',
            message: 'usf.addToBasket.success',
          }),
        ),
      ),
    )(fqdns);
    // if any of the items fails to add to the basket, we want to
    // reject the linked promise
    const failed$ = r.pipe(
      select(ADD_TO_BASKET_FAILED),
      // ignore messages related to other products
      mostc.filter(
        r.pipe(
          r.path([ 'meta', 'productId' ]),
          r.contains(r.__, action.payload.fqdns),
        ),
      ),
      mostc.tap(() => {
        action.payload.reject();
      }),
      // we don't return any messages as the addToBasket will
      // already emit some RK signals
      mostc.map(() => most.empty()),
      most.switchLatest,
    )(actions$);

    return most.mergeArray([ addToBasket$, finished$, failed$ ]);
  },
});

const transferEpic = () => createEpic({
  signal: TRANSFER,
  onSuccess: (action: Transfer) => addDomainToBasket(
    action.payload.fqdn,
    'TRANSFERS',
    false,
    action.payload.resolve,
    action.payload.reject,
  ),
});

const backorderEpic = () => createEpic({
  signal: BACKORDER,
  onSuccess: (action: Backorder) => addDomainToBasket(
    action.payload.fqdn,
    'BACK_ORDER',
    false,
    action.payload.resolve,
    action.payload.reject,
  ),
});

// eslint-disable-next-line no-underscore-dangle
export const __test__ = {
  purchaseBundleEpic,
};

export default () => combineEpics([
  purchaseEpic(),
  purchaseBundleEpic(),
  transferEpic(),
  backorderEpic(),
]);
