import {
  GetLocation,
  GetQuery,
  GetPath,
  Navigate,
} from 'core/navigation';

import qs from 'qs';
import * as r from 'ramda';

const getLocation = (global: Window): GetLocation => () => {
  return global.location;
};

const getQuery = (getLocation: GetLocation): GetQuery => () => {
  const location = getLocation();

  return qs.parse(location.search, { ignoreQueryPrefix: true }) as { [key: string]: string };
};

const defaultPathname = (fallback: string) => r.when(
  r.anyPass([
    r.isEmpty,
    r.isNil,
  ]),
  r.always(fallback),
);

const getPath = (getLocation: GetLocation): GetPath => (fallback: string = '/') => {
  return r.pipe(
    getLocation,
    r.prop('pathname'),
    defaultPathname(fallback),
  //@ts-ignore
  )(null);
};

const navigate = (getLocation: GetLocation): Navigate => (path) => {
  const location = getLocation();

  location.href = path;
};

export default (
  global: Window,
) => {
  /* eslint-disable no-underscore-dangle */
  const _getLocation = getLocation(global);
  const _getQuery = getQuery(_getLocation);
  const _getPath = getPath(_getLocation);
  const _navigate = navigate(_getLocation);
  /* eslint-enable */

  return {
    getLocation: _getLocation,
    getQuery: _getQuery,
    getPath: _getPath,
    navigate: _navigate,
    history: global.history,
  };
};
