import * as r from 'ramda';

export const isProxyUrl = (proxies: string[]) => r.pipe(
  r.defaultTo(''),
  r.flip(r.startsWith),
  //@ts-ignore
  r.any(r.__, proxies),
);

export const isInternalUrl = r.pipe(
  r.defaultTo(''),
  (s:string) => s.charAt(0),
  r.equals('/'),
);

export const isHashUrl = r.pipe(
  r.defaultTo(''),
  (s: string) => s.charAt(0),
  r.equals('#'),
);
