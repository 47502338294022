import * as r from 'ramda';
import { BreakpointProps } from '@ux/responsive';

interface anyProps extends BreakpointProps {
  [key: string]: any,
}
export default (props: anyProps) => r.pick([
  'isMinSm',
  'isMinMd',
  'isMinLg',
  'isMinXl',
], props) as anyProps;
