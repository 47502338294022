import { Fetch } from 'core/configurator';

import { createViewAggregateEpic as createEpic } from '@ux/view-aggregate';
import {
  signals as rkSignals,
  KnowledgeType,
} from '@ux/reactive-knowledge';
import { initialize } from 'redux-form';
import { select, combineEpics } from 'redux-most';
import * as mostc from 'mostc';
import * as r from 'ramda';
import { mapKeys } from 'crosscutting/ramda';

import {
  FETCH,
  Fetch as FetchSignal,
} from 'application/signals/configurator';
import {
  FETCHED,
  FETCH_FAILED,
  fetched,
  fetchFailed,
} from 'domain/messages/configurator';
import {
  getBrandId,
} from 'domain/selectors/common';
import {
  getInitialValues,
  getLocales,
} from 'domain/selectors/configurator';
import { Store } from 'redux';
import { Stream } from 'most';

const fetchEpic = (
  fetch: Fetch,
) => createEpic({
  key: 'configurator',
  signal: FETCH,
  fetch: (action: FetchSignal, state) => {
    const brandId = getBrandId(state);
    const locales = getLocales(state);
    const productId = action.payload.productId;

    return fetch(brandId, locales, productId);
  },
  onSuccess: fetched,
  onFailure: fetchFailed,
});

const fetchedEpic = () => (actions$: Stream<any>, store: Store) => r.pipe(
  select(FETCHED),
  mostc.delay(100),
  mostc.map(() => {
    const state = store.getState();
    const intialValues = r.pipe(
      getInitialValues,
      mapKeys(btoa),
      r.assoc('quantity', 1),
    //@ts-ignore
    )(state);

    return initialize(
      'usf.configurator',
      intialValues,
    );
  }),
)(actions$);

const failedEpic = () => r.pipe(
  select(FETCH_FAILED),
  mostc.map(r.always(rkSignals.add({
    type: KnowledgeType.ERK,
    message: 'usf.configurator.fetch.failed',
  }))),
);

export default (fetch: Fetch) => combineEpics([
  fetchEpic(fetch),
  //@ts-ignore
  fetchedEpic(),
  //@ts-ignore
  failedEpic(),
]);
