import { Type } from 'domain/constants/ribbons';
import * as r from 'ramda';
import { RibbonRaidType } from 'presentation/components/RibbonRaid/RibbonRaid';
import {
  getNextRibbon,
  getPreviousRibbon,
} from '../../RibbonRaid/utils';

const duoRibbons = [
  Type.BILLBOARD_GENERIC,
  Type.BILLBOARD_PRODUCT,
  Type.BILLBOARD_DOMAIN,
];

const haveDuoProps = (ribbonA: RibbonRaidType, ribbonB: RibbonRaidType) => {
  const duoRibbonA = r.pathOr(false, [ 'props', 'layout', 'duo' ], ribbonA);
  const duoRibbonB = r.pathOr(false, [ 'props', 'layout', 'duo' ], ribbonB);
  return duoRibbonA && duoRibbonB;
};

export default (
  ribbon: RibbonRaidType,
  idx: number,
  ribbons: RibbonRaidType[],
  isMinMd: boolean
) => {
  if (!isMinMd) {
    return ribbon;
  }
  if (duoRibbons.indexOf(ribbon.type) < 0) {
    return ribbon;
  }

  return r.pipe(
    r.when(
      () => {
        const nextRibbon = getNextRibbon(idx, ribbons);
        return haveDuoProps(ribbon, nextRibbon);
      },
      r.assocPath([ 'props', 'layout', 'startDuoLayout' ], true)
    ),
    r.when(
      () => {
        const prevRibbon = getPreviousRibbon(idx, ribbons);
        return haveDuoProps(ribbon, prevRibbon);
      },
      r.assocPath([ 'props', 'layout', 'endDuoLayout' ], true)
    ),
  )(ribbon);
};
