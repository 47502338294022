import { push } from 'connected-react-router';
import * as r from 'ramda';
import { externalPush } from './signals/root';
import { isInternalUrl } from 'domain/url';

export const getPushAction = r.ifElse(
  isInternalUrl,
  push,
  externalPush,
);

export default null;
