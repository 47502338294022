import React, { Suspense, lazy } from 'react';

const ReactQueryDevtools = (() => {
  const Fallback = (): null => null;
  if (process.env.NODE_ENV === 'production') {
    return Fallback;
  }
  const Devtools = lazy(async() => {
    const {
      ReactQueryDevtools,
      // @ts-ignore
    } = await import('react-query-devtools/dist/react-query-devtools.development.js');
    return {
      default: ReactQueryDevtools,
    };
  });

  return () => (
    <Suspense fallback={<Fallback/>}>
      <Devtools/>
    </Suspense>
  );
})();

export default ReactQueryDevtools;
