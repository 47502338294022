import { Core } from 'core';

import { combineEpics } from 'redux-most';

import addToBasket from './addToBasket';
import addVoucher from './addVoucher';
import postAction from './postAction';

export default (deps: Core) => combineEpics([
  addToBasket(
    deps.basket.addToBasket,
    deps.basket.addDomainToBasket,
    deps.dispatchFBEvent,
    deps.bus,
  ),
  addVoucher(deps.basket.applyVoucher),
  postAction(),
]);
