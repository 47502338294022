
import React from 'react';
import * as r from 'ramda';
import { RibbonRaidType } from '../RibbonRaid/RibbonRaid';
import { Type as RibbonType } from 'domain/constants/ribbons';
import { isNotNilOrEmpty } from '@team-griffin/capra';
import { withProps } from '@team-griffin/rehook';
import Helmet from 'react-helmet';
import { puree, GetInnerProps } from '@ux/pure-enhance';
import { ShortcodeProps } from '../Shortcode';

interface OuterProps {
  ribbons: RibbonRaidType[],
}

export interface MainEntityType {
  '@type': string,
  'name': string,
  'acceptedAnswer': {
    '@type': string,
    'text': string,
  }
}

interface ItemType {
  answer: ShortcodeProps,
  defaultOpen: boolean,
  question: 'Q3'
}

const createAnswer = (answer: ShortcodeProps) => {
  const parsedAnswer = [] as string[];
  let stringedAnswer = '' as string;
  const explodeShortcode = (shortcode: ShortcodeProps) => {
    if (typeof shortcode === 'string') {
      parsedAnswer.push(shortcode);
      return;
    }
    const toParse = shortcode?.elements ?? shortcode;
    toParse.forEach(function(value: ShortcodeProps) {
      if (typeof value === 'string' && value) {
        parsedAnswer.push(value);
      }
      if (value.type === 'text' && value.text) {
        parsedAnswer.push(value.text);
      }
      if (value.name === 'tooltip' && value.elements && value.attributes.label) {
        //eslint-disable-next-line prefer-template
        parsedAnswer.push(value.attributes.label + ' (');
      }
      if (value.elements) {
        explodeShortcode(value);
      }
      if (value.name === 'tooltip' && value.elements && value.attributes.label) {
        //eslint-disable-next-line prefer-template
        parsedAnswer.push(')');
      }
    });
  };
  explodeShortcode(answer);
  //transform back into array
  stringedAnswer = parsedAnswer.join('');
  //sometimes we start with a newline, we don't want to change it to a comma
  stringedAnswer = stringedAnswer.startsWith('\n') ? stringedAnswer.substring(1) : stringedAnswer;
  //sometimes we end with a newline, we don't want to change it to a comma
  //eslint-disable-next-line
  stringedAnswer = stringedAnswer.endsWith('\n') ? `${stringedAnswer.slice(0, stringedAnswer.length - 1)}.` : stringedAnswer;
  //replace full stops
  stringedAnswer = stringedAnswer.split('\n\n').join('. ');
  //replace start line stops
  stringedAnswer = stringedAnswer.split(' \n').join(' ');
  // replace end line stops
  stringedAnswer = stringedAnswer.split('\n').join(', ');
  //trim any remaining noise
  stringedAnswer = stringedAnswer.trim();
  return stringedAnswer;
};

const createMainEntity = (itemsSets: ItemType[][]) => {
  const mainEntity = [] as MainEntityType[];
  r.forEach(
    (itemsSet: ItemType[]) => r.forEach(
      (item: ItemType) => {
        mainEntity.push({
          '@type': 'Question',
          'name': item.question,
          'acceptedAnswer': {
            '@type': 'Answer',
            //replace multiple spaces
            'text': createAnswer(item.answer),
          },
        });
      },
      itemsSet,
    ),
    itemsSets,
  );
  return mainEntity;
};

export const getJjsonLdData = r.pipe(
  r.filter(
    (value: RibbonRaidType) => value.type === RibbonType.ELABORATE && value.props.context === 'FAQ'
  ),
  //@ts-ignore
  r.map(r.path([ 'props', 'items' ])),
  /*
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How do domain names work?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Every site is hosted on an internet web server, or a “host”."
      }
    },{
      "@type": "Another question",
      "name": "How does VPS work?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "VPS works in mysterious ways."
      }
    }]
  }
  */
  r.ifElse(
    r.isEmpty,
    r.always(null),
    (faqRibbons) => ({
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      'mainEntity': createMainEntity(faqRibbons),
    }),
  ),
);

const enhance = puree<OuterProps>('FaqJsonLd')(
  withProps((props) => ({
    //@ts-ignore getJsonLDData DOES require an argument
    jsonLD: getJjsonLdData(props.ribbons),
  })),
  withProps((props) => ({
    hasJsonLD: isNotNilOrEmpty(props.jsonLD),
  })),
);

type InnerProps = GetInnerProps<typeof enhance>;

const PureFaqJsonLd = ({
  jsonLD,
  hasJsonLD,
}: InnerProps) => (
  <Helmet>
    <If condition={hasJsonLD}>
      <script type="application/ld+json">
        {JSON.stringify(jsonLD)}
      </script>
    </If>
  </Helmet>
);


export default enhance(PureFaqJsonLd);
