import { RequestCallback } from 'core/ribbons/callback';
import { Middleware } from '@ux/drivers';

const requestCallback = (
  middleware: Middleware,
): RequestCallback => (
  brandId: string,
  locale: string,
  name: string,
  company: string,
  email: string,
  phone: string,
  date: Date,
  message: string,
  captcha: string,
) => {
  return middleware(0, 'requestCallBack', {
    brandId,
    locale,
    name,
    company,
    email,
    phone,
    date,
    message,
    captcha,
  });
};

export default requestCallback;
