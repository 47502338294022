import { PageCache } from '../utils/pageCache';
import {
  GetPath,
  GetQuery,
} from 'core/navigation';

import _fetch from './fetch';
import _callback from './callback';
import _domainSearch from './domain-search';
import { Middleware } from '@ux/drivers';

export default (
  ndjson: Function,
  getPath: GetPath,
  getQuery: GetQuery,
  document: Document,
  middleware: Middleware,
  pageCache: PageCache,
  dispatchGAEvent: Function,
) => {
  const fetch = _fetch(pageCache, getPath, getQuery, ndjson, dispatchGAEvent);
  const callback = _callback(middleware);
  const domainSearch = _domainSearch(document);

  return {
    fetch,
    callback,
    domainSearch,
  };
};
