import { GetProductData } from 'core/cta';
import { createEpic } from '@ux/fabric';
import { selectors as product } from '@ux/product';
import browserCookies from 'browser-cookies';
import * as r from 'ramda';
import { PRODUCT_CLICKED } from 'application/signals/cta';

const {
  getPrice,
} = product;

const productEpic = (
  getProductData: GetProductData,
  ecommerce: Function,
) => createEpic({
  signal: PRODUCT_CLICKED,
  process: (action: any) => {
    const {
      payload: {
        productId,
        list,
      },
    } = action;
    const locale = browserCookies.get('ux-language-preferred');

    if (!productId) {
      return;
    }

    return getProductData({
      skuId: productId,
      locale,
    })
      .then((product: any) => {
        const id = r.prop('id', product);
        const name = r.prop('name', product);
        const price = getPrice({
          product,
          includeTax: false,
        });

        ecommerce([
          [
            'addProduct',
            {
              id: `${id}`,
              name,
              price,
            },
          ],
          [
            'setAction',
            'click',
            { list },
          ],
        ]);
      });
  },
});

export default productEpic;
