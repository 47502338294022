import { combineReducers } from 'redux';
import root, { State as Root } from './root';
import ribbons, { State as Ribbons } from './ribbons';
import configurator, { State as Configurator } from './configurator';

export type State = {
  root: Root,
  ribbons: Ribbons,
  configurator: Configurator,
};

export default combineReducers({
  root,
  ribbons,
  configurator,
});
