import { createAction, Action } from 'redux-actions';
import { payloadCreator } from '@ux/fabric';

const prefix = '@@usf/root/M_';
export const PAGE_CHANGED = `${prefix}PAGE_CHANGED`;

export type PageChanged = Action<{
  page: string,
}>;

export const pageChanged = createAction(
  PAGE_CHANGED,
  payloadCreator([ 'page' ]),
);
