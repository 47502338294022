import { createAction, Action, ActionMeta } from 'redux-actions';
import { payloadCreator } from '@ux/fabric';

const prefix = '@@usf/basket/S_';
export const ADD_TO_BASKET = `${prefix}ADD_TO_BASKET`;
export const ADD_DOMAIN_TO_BASKET = `${prefix}ADD_DOMAIN_TO_BASKET`;
export const APPLY_VOUCHER = `${prefix}APPLY_VOUCHER`;

export type AddToBasket = Action<{
    buttonId: string,
    productId: string,
    postAction: {
      type: string,
      url: string,
    },
    fqdn?: string,
    pricingTier?: number|string,
    quantity?: number,
    productData?: {
      name: string,
      price: number,
      category: string,
    },
}>;
export type AddDomainToBasket = ActionMeta<{
  fqdn: string,
  type: string,
  silent: boolean,
}, {
  resolve: Function,
  reject: Function,
}>;
export type ApplyVoucher = Action<{
  buttonId: string,
  voucherCode: string,
  postAction: {
    type: string,
    url: string,
  },
}>;

export const addToBasket = createAction(
  ADD_TO_BASKET,
  // @ts-ignore
  payloadCreator([
    'buttonId',
    'productId',
    'postAction',
    'pricingTier',
    'quantity',
    'productData',
  ]),
);

export const addDomainToBasket = createAction(
  ADD_DOMAIN_TO_BASKET,
  payloadCreator([ 'fqdn', 'type', 'silent' ]),
  payloadCreator([ 'resolve', 'reject' ], 3),
) as any;
export const applyVoucher = createAction(
  APPLY_VOUCHER,
  payloadCreator([ 'buttonId', 'voucherCode', 'postAction' ]),
);
