import { createSelector } from 'reselect';
import { selectors as wlSelectors } from '@ux/whitelabel';
import * as r from 'ramda';

export const getBrandId = wlSelectors.getBrandId;

export const getLocale = wlSelectors.getLocale;

export const getCurrency = createSelector<any, any, string>(
  wlSelectors.getBrandConfig,
  r.path([ 'payment', 'currency' ]),
);

export const getExternalRoutes = createSelector(
  wlSelectors.getAppConfig('usf'),
  r.propOr([], 'externalRoutes'),
);

export const getLocation = r.path([ 'router', 'location' ]);

export const getPath = createSelector<any, any, string>(
  getLocation,
  r.prop('pathname'),
);

export const isConfiguratorPage = createSelector(
  getPath,
  r.startsWith('/configuration'),
);

export const isRibbonsPage = createSelector(
  isConfiguratorPage,
  r.not,
);