import { Core } from 'core';

import { combineEpics } from 'redux-most';

import initializeEpic from './initialize';
import purchaseEpic from './purchase';
import viewBasketEpic from './viewBasket';
import updateQueryParamEpic from './search';

export default (deps: Core) => combineEpics([
  // @ts-ignore
  initializeEpic(deps.ribbons.domainSearch.listen),
  purchaseEpic(),
  viewBasketEpic(deps.navigation.navigate),
  updateQueryParamEpic(
    deps.navigation.getPath,
    deps.navigation.history
  ),
]);
