import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import { map } from '@ux/promise';
import { GetProductData } from 'core/cta';
import {
  SEND_IMPRESSION,
  SendImpression,
} from 'application/signals/ribbons';
import { promoNameToPromoId } from 'domain/logic/promotion';
import { createRibbonName } from 'domain/transformers/ribbons';

const getProductIds = (productIds = '') => productIds
  .toString()
  .split(',')
  .map((id: string) => id.trim());

const productImpressionEpic = (
  getProductData: GetProductData,
  ecommerce: Function,
) => createEpic({
  signal: SEND_IMPRESSION,
  filter: (data) => data?.payload?.type === 'PRODUCT' && data?.payload?.view === 'IMPRESSION',
  process: (action: SendImpression) => {
    const productIds = getProductIds(action.payload.productId);
    const list = createRibbonName(action.payload.ribbonType, action.payload.ribbonName);

    const promises = productIds.map((id) => {
      const productData = getProductData({
        productId: id,
      });

      return map((product: {
        id?: string,
        name?: string,
        [key: string]: any,
      }) => [
        'addImpression',
        {
          id: product.id,
          name: product.name,
          list,
        },
      ])(productData);
    });

    return Promise.all(promises).then((events) => {
      ecommerce(events, { nonInteraction: true });
    });
  },
});

const productDetailEpic = (
  getProductData: GetProductData,
  ecommerce: Function,
) => createEpic({
  signal: SEND_IMPRESSION,
  filter: (data) => data?.payload?.type === 'PRODUCT' && data?.payload?.view === 'DETAIL',
  process: (action: SendImpression) => {
    const productIds = getProductIds(action.payload.productId);

    const promises = productIds.map((id) => {
      const productData = getProductData({
        productId: id,
      });

      return map((product: {
        id?: string,
        name?: string,
        [key: string]: any,
      }) => [
        'addProduct',
        {
          id: product.id,
          name: product.name,
        },
      ])(productData);
    });

    return Promise.all(promises).then((events) => {
      ecommerce([
        ...events,
        [ 'setAction', 'detail' ],
      ], { nonInteraction: true });
    });
  },
});

// promo impression
const promoImpressionEpic = (
  ecommerce: Function,
) => createEpic({
  signal: SEND_IMPRESSION,
  filter: (data) => data?.payload?.type === 'PROMOTION',
  process: (action: SendImpression) => {
    const promotion = action.payload.promotion;
    if (!promotion) {
      return;
    }
    const promoId = promoNameToPromoId(promotion);
    const list = createRibbonName(action.payload.ribbonType, action.payload.ribbonName);

    ecommerce([
      [
        'addImpression',
        {
          id: promoId,
          name: promotion,
          list,
        },
      ],
    ], { nonInteraction: true });
  },
});

// eslint-disable-next-line no-underscore-dangle
export const __test__ = {
  productImpressionEpic,
  productDetailEpic,
  promoImpressionEpic,
};

export default (
  getProductData: GetProductData,
  ecommerce: Function,
) => combineEpics([
  productImpressionEpic(getProductData, ecommerce),
  productDetailEpic(getProductData, ecommerce),
  promoImpressionEpic(ecommerce),
]);
