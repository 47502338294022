import { useStyles } from '@team-griffin/stylr';
import { CSSProperties } from 'react';

export interface Styles {
  root: CSSProperties,
}

export default ({
  leadingHeightOffset,
  hasLeadingRibbons,
}: {
  leadingHeightOffset: number,
  hasLeadingRibbons: boolean,
}) => {
  return useStyles({
    default: {
      root: {
      },
    },
    leadingFullscreen: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: `calc(100vh - ${leadingHeightOffset}px)`,
      },
    },
  }, [
    { leadingFullscreen: hasLeadingRibbons },
  ]);
};
