import React, { Suspense } from 'react';
import {
  compose,
  setDisplayName,
  withProps,
} from 'recompose';
import { Content } from '@ux/typeface';
import { SalesHeader, Footer } from '@ux/bookends';
import { useStyles } from '@team-griffin/stylr';

const stylesheet = ({
  theme: {
    palette,
  },
}) => useStyles({
  default: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'auto',
    },
    main: {
      minHeight: 300,
      flex: 1,
      flexBasis: 'auto',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: palette.grey12,
    },
  },
});

const enhance = compose(
  setDisplayName('Layout'),
  // fastCompare,
  withProps(stylesheet),
);

export const PureLayout = enhance(({
  theme,
  styles,
  header,
  footer,
  children,
}) => (
  <Content
    theme={theme}
    component="div"
    style={styles.root}
  >
    {header}
    <main style={styles.main}>
      {children}
    </main>
    {footer}
  </Content>
));

export default ({
  theme,
  externalRoutes,
  languageMap,
  children,
}) => (
  <PureLayout
    theme={theme}
    header={(
      <Suspense fallback={<div/>}>
        <SalesHeader
          theme={theme}
          externalRoutes={externalRoutes}
          languageMap={languageMap}
        />
      </Suspense>
    )}
    footer={(
      <Suspense fallback={<div/>}>
        <Footer
          theme={theme}
          externalRoutes={externalRoutes}
        />
      </Suspense>
    )}
  >
    {children}
  </PureLayout>
);
