import { createAction, Action } from 'redux-actions';

const prefix = '@@usf/domain-search-start/S_';
export const SUBMIT = `${prefix}SUBMIT`;

export type Submit = Action<{
  domain: string,
  tld: string,
  destination: string,
  form: string,
  ribbonType: string,
  ribbonName?: string,
}>;

export const submit = createAction(SUBMIT);
