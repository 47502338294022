import { RequestCallback } from 'core/ribbons/callback';

import { curry2 } from '@team-griffin/capra';
import * as mostc from 'mostc';
import * as mA from 'most-adjunct';
import * as most from 'most';
import { select, combineEpics } from 'redux-most';
import {
  change,
  setSubmitSucceeded,
  stopSubmit,
} from 'redux-form';
import * as r from 'ramda';
import {
  getBrandId,
  getLocale,
} from 'domain/selectors/common';

import {
  SUBMIT_DETAILS,
  SubmitDetails,
} from 'application/signals/ribbons/callback';
import {
  CALLBACK_SUCCEEDED,
  CALLBACK_FAILED,
  CallbackSucceeded,
  callbackSucceeded,
  callbackFailed,
} from 'domain/messages/ribbons/callback';
import { Store } from 'redux';

export const submitDetailsEpic = (
  requestCallback: RequestCallback,
) => (actions$: most.Stream<any>, store: Store) => r.pipe(
  select(SUBMIT_DETAILS),
  mA.switchMap((action: SubmitDetails) => {
    const state = store.getState();
    const brandId = getBrandId(state);
    const locale = getLocale(state);
    const {
      name,
      company,
      email,
      phone,
      date,
      message,
      captcha,
    } = action.payload.values;

    const details$ = mA.fromEagerPromise(() => requestCallback(
      brandId,
      locale,
      name,
      company,
      email,
      phone,
      date,
      message,
      captcha,
    ));

    return r.pipe(
      mostc.map(
        curry2(callbackSucceeded)(r.__, action.meta.form),
      ),
      mostc.recoverWith(
        r.pipe(
          // @ts-ignore
          curry2(callbackFailed)(r.__, action.meta.form),
          most.of,
        ),
      ),
    )(details$);
  }),
)(actions$);

const successEpic = () => r.pipe(
  select(CALLBACK_SUCCEEDED),
  mA.switchMap((action: CallbackSucceeded) => most.from([
    stopSubmit(action.meta.form),
    setSubmitSucceeded(action.meta.form),
    change(action.meta.form, '__arranged_date__', action.payload.data.date),
  ])),
);

const failedEpic = () => r.pipe(
  select(CALLBACK_FAILED),
  mostc.map(
    r.converge(
      stopSubmit,
      [
        r.path([ 'meta', 'form' ]),
        r.cond([
          [
            r.both(
              r.propEq('code', 5000),
              r.propEq('error', 'InvalidCaptchaError'),
            ),
            r.always({
              captcha: 'usf.ribbons.callback.errors.captcha.invalid',
            }),
          ],
          [
            r.propEq('code', 5000),
            r.always({
              _error: 'usf.ribbons.callback.errors.emailFailed',
            }),
          ],
          [
            r.T,
            r.always({
              _error: 'usf.ribbons.callback.errors.unknown',
            }),
          ],
        ]),
      ],
    )
  ),
);

export default (
  requestCallback: RequestCallback,
) => combineEpics([
  //@ts-ignore
  submitDetailsEpic(requestCallback),
  //@ts-ignore
  successEpic(),
  //@ts-ignore
  failedEpic(),
]);
