import {
  GetPath,
  GetQuery,
} from 'core/navigation';
import { PageCache } from '../../utils/pageCache';
import _fetch from './fetch';
import _fetchFromApi from './fetchFromApi';
import _fetchFromCache from './fetchFromCache';
import _processPageFetched from './processPageFetched';
import _processRibbonFinished from './processRibbonFinished';

export default (
  pageCache: PageCache,
  getPath: GetPath,
  getQuery: GetQuery,
  ndjson: Function,
  dispatchGAEvent: Function,
) => {
  const processRibbonFinished = _processRibbonFinished(pageCache);
  const processPageFetched = _processPageFetched(pageCache);
  const fetchFromCache = _fetchFromCache(pageCache);
  const fetchFromApi = _fetchFromApi(
    ndjson,
    getQuery,
    processPageFetched,
    processRibbonFinished,
    dispatchGAEvent,
  );
  const fetch = _fetch(getPath, pageCache, fetchFromCache, fetchFromApi);

  return fetch;
};
