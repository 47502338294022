import * as mostc from 'mostc';
import * as mA from 'most-adjunct';
import { select } from 'redux-most';
import * as r from 'ramda';
// import { signals as orchestrationSignals } from '@ux/orchestration';
import { messages as wlMessages } from '@ux/whitelabel';
import { bootstrap } from '@pex/upm';
import {
  INITIALIZE,
} from 'application/signals/root';
import {
  getTealiumCategories,
} from 'domain/selectors/root';
import { Stream } from 'most';
import { Store } from 'redux';

const initializeEpic = () => (actions$: Stream<any>, store: Store) => r.pipe(
  select(INITIALIZE),
  mA.switchMap(() => {
    const bootstrap$ = r.pipe(
      select(wlMessages.APP_CONFIG_SUCCESS),
      mostc.map(() => {
        const state = store.getState();
        const categories = getTealiumCategories(state);
        return bootstrap({
          cookiesToPreserve: [
            'brand_id',
            'market',
            'auth_idp',
            'info_idp',
            'ux-language-preferred',
          ],
          categories,
        });
      }),
    )(actions$);
    return bootstrap$;
  }),
)(actions$);

export default initializeEpic;
