
import {
  Epic,
  createEpicMiddleware,
  combineEpics,
} from 'redux-most';

// Epics
import { epics as reactiveKnowledgeEpics } from '@ux/reactive-knowledge';
import { epic as modalEpics } from '@team-griffin/redux-modal-router';
import { epic as pageLoaderEpic } from '@team-griffin/redux-page-loader';
import { epics as dstEpics } from '@pex/dst';
import { epic as scrollEpic } from '@ux/auto-scroll';

export default (
  appEpics: Epic<any, any>
) => createEpicMiddleware(
  combineEpics([
    appEpics,
    reactiveKnowledgeEpics(),
    modalEpics,
    pageLoaderEpic,
    dstEpics,
    scrollEpic(),
  ]),
);