import { Fetch } from 'core/ribbons';
import {
  GetPath,
} from 'core/navigation';
import { PageCache } from '../../utils/pageCache';
import { FetchFromApi } from './fetchFromApi';
import { FetchFromCache } from './fetchFromCache';

const fetch = (
  getPath: GetPath,
  pageCache: PageCache,
  fetchFromCache: FetchFromCache,
  fetchFromApi: FetchFromApi,
): Fetch => (
  locales: string[],
  path: string = getPath('/'),
  canFetchFromCache: boolean = true
) => {
  if (canFetchFromCache && pageCache.has(path)) {
    return fetchFromCache(path);
  }

  return fetchFromApi(locales, path);
};

export default fetch;
