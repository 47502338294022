import * as r from 'ramda';

// "My Promotion" -> "my_promotion"
// as GA ids have to adhere to certain rules (like no spaces)
// and we don't get a specific id, just a promotion name
// we remove spaces and strip any invalid characters (anything that's not a letter, number, or _)
export const promoNameToPromoId = r.pipe(
  r.toLower,
  r.replace(/ +?/g, '_'),
  r.replace(/[^a-z0-9_]/gmi, ''),
);

export default null;
