import { createAction, Action } from 'redux-actions';

const prefix = '@@usf/configurator/M_';
export const FETCHED = `${prefix}FETCHED`;
export const FETCH_FAILED = `${prefix}FETCH_FAILED`;

export type Fetched = Action<{}>;
export type FetchFailed = Action<Error>;

export const fetched = createAction(FETCHED);
export const fetchFailed = createAction(FETCH_FAILED);
