import { NotifyCradle } from 'core/root';
import * as mostc from 'mostc';
import * as mA from 'most-adjunct';
import { select } from 'redux-most';
import * as r from 'ramda';
import {
  RIBBONS_FAILED,
  RibbonsFailed,
} from 'domain/messages/ribbons/index';
import { Stream } from 'most';
import { Level } from '@ux/cradle';

export const cradleEpic = (
  notifyCradle: NotifyCradle,
) => (actions$: Stream<any>) => r.pipe(
  select(RIBBONS_FAILED),
  mostc.tap((action: RibbonsFailed) => {
    // eslint-disable-next-line no-console
    console.error(action.payload);
    const {
      error,
      message,
    } = r.cond([
      [
        r.pathEq([ 'status' ], 500),
        r.always({
          error: 'API-500',
          message: 'Fetch page 500',
        }),
      ],
      [
        r.has('status'),
        (err) => ({
          error: err.status,
          message: err.statusText,
        }),
      ],
      [
        r.is(Error),
        (err) => ({
          error: 'Uncaught-Exception',
          message: err.stack || err.message,
        }),
      ],
      [
        r.T,
        r.always({
          error: 'Unknown',
          message: '',
        }),
      ],
    ])(action.payload);

    return notifyCradle(Level.CRITICAL, error, message);
  }),
  mA.ignoreElements
)(actions$);

export default cradleEpic;
