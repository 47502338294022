import { AddToBasket } from 'core/basket';
import { CreateDelayedQueue } from '@ux/queue';
import * as r from 'ramda';
import { Middleware } from '@ux/drivers';
const add = (
  middleware: Middleware,
  createQueue: CreateDelayedQueue,
): AddToBasket => {
  let busy = false;
  const test = () => !busy;
  const action = (
    brandId: string,
    productId: string,
    pricingTier: number,
    addons: any,
    quantity?: number,
  ): Promise<{}> => {
    // if we're already adding an item to the basket, we add this request back to the queue
    // and try again in a second
    if (busy) {
      // eslint-disable-next-line no-use-before-define
      return queue(brandId, productId, pricingTier, addons, quantity);
    }
    busy = true;

    return r.pipe(
      r.ifElse(
        () => r.isNil(pricingTier),
        r.always({}),
        r.assoc('pricingTier', pricingTier),
      ),
      r.unless(
        () => r.isNil(quantity),
        r.assoc('quantity', quantity),
      ),
      r.assoc('brandId', brandId),
      r.assoc('skuId', productId),
      r.assoc('addonSkuIds', addons),
      (data) => middleware(0, 'addBasketItem', data),
      // flag the function as free again
      (promise) => promise.then(
        (x: any) => {
          busy = false;
          return x;
        },
        (x: any) => {
          busy = false;
          throw x;
        },
      ),
    )({});
  };

  const queue = createQueue(action, test);
  return queue;
};

export default add;
