import React, { Suspense, lazy } from 'react';
import {
  compose,
  setDisplayName,
  withProps,
} from 'recompose';
import * as r from 'ramda';
import getLanguage from './getLanguage';
import getLanguages from './getLanguages';
import criticalCSS from 'presentation/assets/critical.css';

const Helmet = lazy(() => import('react-helmet').then((module) => ({ default: module.Helmet })));

const PureHead = ({
  lang,
  languages,
  canonicalUrl,
  showLinks,
}) => (
  <Suspense fallback={null}>
    <Helmet>
      <html lang={lang}/>
      <If condition={showLinks}>
        <If condition={canonicalUrl}>
          <link
            rel="canonical"
            href={canonicalUrl}
          />
        </If>
        <For
          of={languages}
          each="language"
          index="i"
        >
          <link
            key={i}
            rel="alternate"
            href={`${window.location.protocol}//${window.location.host}${language.url}`}
            // eslint-disable-next-line
            hreflang={language.lang}
          />
        </For>
      </If>
      <style>{criticalCSS}</style>
    </Helmet>
  </Suspense>
);

const enhance = compose(
  setDisplayName('Head'),
  withProps((ownerProps) => {
    return {
      lang: getLanguage(ownerProps),
      languages: getLanguages(ownerProps),
      canonicalUrl: [
        window.location.protocol,
        '//',
        window.location.host,
        r.prop('pagePath', ownerProps),
      ].join(''),
      // we don't want to render canonical links when we're on a 404 page
      // as it's an unknown page, there _are_ no canonical links
      // because of this we also get /null for the alt links!
      showLinks: r.not(r.propEq('pageStatus', 404, ownerProps)),
    };
  }),
);

export default enhance(PureHead);
