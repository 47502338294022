export type PageCache = {
  has: (key: string) => boolean,
  get: (key: string) => any,
  put: (key: string, value: any) => void,
  clear: () => void,
};

export default function PageCacheFactory(): PageCache {
  //really bad typing, key apparently can't be string
  //and if you add typing key can be any. but not string.
  const cache = {} as {
    [key: string]: any,
   }[];
  return {
    has: (key: any) => {
      return cache[key] !== void 0;
    },
    put: (key: any, value: any) => {
      cache[key] = value;
    },
    get: (key: any) => {
      return cache[key];
    },
    clear: () => {
      Object.keys(cache).forEach((key: any) => delete cache[key]);
    },
  };
}
