import { createReducer } from 'redux-create-reducer';
import * as r from 'ramda';

import {
  PAGE_CHANGED,
  PageChanged,
} from 'domain/messages/root';

export type State = {
  page: string,
};

export const initialState: State = {
  page: '',
};

const reducer = createReducer(initialState, {
  [PAGE_CHANGED]: (state: State, action: PageChanged) => r.assoc(
    'page',
    action.payload.page,
    state,
  ),
});

export default reducer;
