import {
  Fetch,
} from 'core/configurator';

import normalize from 'domain/transformers/configurator';
import { Middleware } from '@ux/drivers';

const fetch = (
  middleware: Middleware,
): Fetch => (
  brandId,
  locales,
  skuId,
) => {
  return middleware(0, 'viewAggregate.configurator', {
    brandId,
    locales,
    skuId,
  }).then(normalize);
};

export default fetch;
