import { createEpic } from '@ux/fabric';
import * as r from 'ramda';

import {
  SET_VISIBILITY,
} from 'application/signals/ribbons';
import {
  setVisibility,
} from 'domain/messages/ribbons';

export default () => createEpic({
  signal: SET_VISIBILITY,
  onSuccess: r.pipe(
    r.nthArg(1),
    r.prop('payload'),
    setVisibility,
  ),
});
