import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useResolve } from 'react-jpex';
import { useCurrency } from '@ux/currency';
import { fetch } from 'application/signals/ribbons';
import { ClearCache } from 'core/cache';

const useCurrencyListener = () => {
  const firstRenderRef = useRef(true);
  const dispatch = useDispatch();
  const { data: currency } = useCurrency();
  const clearCache = useResolve<ClearCache>();

  useEffect(() => {
    // ignore the first pass where currency will be undefined
    if (!currency) {
      return;
    }
    // ignore the initial currency value
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    clearCache();
    dispatch(fetch());
  }, [ currency ]);
};

export default useCurrencyListener;
