import { createAction, Action } from 'redux-actions';

const prefix = '@@usf/domain-search/S_';

export const INITIALIZE = `${prefix}INITIALIZE`;
export const PURCHASE = `${prefix}PURCHASE`;
export const PURCHASE_BUNDLE = `${prefix}PURCHASE_BUNDLE`;
export const VIEW_BASKET = `${prefix}VIEW_BAKET`;
export const TRANSFER = `${prefix}TRANSFER`;
export const BACKORDER = `${prefix}BACKORDER`;
export const UPDATE_QUERY_PARAM = `${prefix}UPDATE_QUERY_PARAM`;

export type Initialize = Action<{}>;
export type Purchase = Action<{
  fqdn: string,
  resolve: Function,
  reject: Function,
}>;
export type PurchaseBundle = Action<{
  fqdns: string[],
  resolve: Function,
  reject: Function,
}>;

export type Transfer = Action<{
  fqdn: string,
  resolve: Function,
  reject: Function,
}>;
export type Backorder = Transfer;
export type ViewBasket = Action<{}>;
export type UpdateQueryParam = Action<{
  query: string,
}>;

export const initialize = createAction(INITIALIZE);
export const purchase = createAction(PURCHASE);
export const purchaseBundle = createAction(PURCHASE_BUNDLE);
export const viewBasket = createAction(VIEW_BASKET);
export const transfer = createAction(TRANSFER);
export const backorder = createAction(BACKORDER);
export const updateQueryParam = createAction(UPDATE_QUERY_PARAM);
