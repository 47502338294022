import { createStructuredSelector } from 'reselect';
import scaffold from '@ux/scaffold-ui';
import { selectors as wlSelectors } from '@ux/whitelabel';
import { mush, withHooks, withProvider } from '@ux/pure-enhance';
import { connect } from '@ux/fabric';
import { usePageView } from '@ux/tagmg';
import { IdpProvider } from '@ux/auth';
import {
  getTealiumCategories,
  getPrivacyPolicyUrl,
  getSupportedLanguages,
} from 'domain/selectors/root';
import { getExternalRoutes } from 'domain/selectors/common';
import {
  getPageStatus,
  getLanguageMap,
  getCurrentLanguage,
  getPagePath,
} from 'domain/selectors/ribbons';

import App from './App';
import { tap, withProps } from '@team-griffin/rehook';
import epicHacks from './epicHacks';

const mapStateToProps = createStructuredSelector({
  categories: getTealiumCategories,
  externalRoutes: getExternalRoutes,
  pagePath: getPagePath,
  pageStatus: getPageStatus,
  currentLanguage: getCurrentLanguage,
  languageMap: getLanguageMap,
  supportedLanguages: getSupportedLanguages,
  privacyPolicyUrl: getPrivacyPolicyUrl,
  market: wlSelectors.getMarket,
});

const enhance = mush('ConnectedApp')(
  withProvider(IdpProvider),
  scaffold({
    apps: [ 'brand', 'usf', 'bks' ],
    scopes: [
      'brand',
      'usf',
      'bks',
      'cookie',
      'dst',
      'ux',
      'country',
    ],
  }),
  withHooks(
    tap(() => {
      usePageView();
      epicHacks();
    }),
    withProps(connect(mapStateToProps)),
  ),
);

export default enhance(App);
