import React, { Suspense, lazy, useMemo } from 'react';
import { puree } from '@ux/pure-enhance';
import {
  componentAdapterMap,
  importRibbon,
} from './utils';
import { Theme } from '@ux/theme-utils';
import { RibbonRaidType, AnyRibbonProps } from './RibbonRaid';
import RibbonPending from '../ribbons/Ribbon/RibbonPending';

interface OuterProps {
  theme: Theme,
  ribbon: RibbonRaidType,
  ribbonProps: AnyRibbonProps,
  nextRibbon: RibbonRaidType,
}

const PureDuoLayout = ({
  theme,
  ribbon,
  ribbonProps,
  nextRibbon,
}: OuterProps) => {
  const nextComponentAdapter = useMemo(
    () => componentAdapterMap[nextRibbon.type],
    [ nextRibbon.type ],
  );
  const nextRibbonProps = useMemo(
    () => nextComponentAdapter(nextRibbon),
    [ nextRibbon ],
  ) as AnyRibbonProps;

  const LazyComponent = useMemo(() => {
    return lazy(() => importRibbon(ribbon.type));
  }, [ ribbon.type ]);
  const NextLazyComponent = useMemo(() => {
    return lazy(() => importRibbon(nextRibbon.type));
  }, [ nextRibbon.type ]);

  const wrapperStyles = {
    display: 'flex',
    flex: 1,
    flexBasis: 'auto',
  };

  return (
    <Suspense
      fallback={(
        <div style={wrapperStyles}>
          <RibbonPending
            theme={theme}
            {...ribbonProps}
          />
          <RibbonPending
            theme={theme}
            {...nextRibbonProps}
          />
        </div>
      )}
    >
      <div style={wrapperStyles}>
        <LazyComponent
          key={ribbon.id}
          id={ribbon.id}
          theme={theme}
          loading={!ribbon.ready}
          duoLayout={true}
          {...ribbonProps}
        />
        <NextLazyComponent
          key={nextRibbon.id}
          id={nextRibbon.id}
          theme={theme}
          loading={!nextRibbon.ready}
          duoLayout={true}
          {...nextRibbonProps}
        />
      </div>
    </Suspense>
  );
};

const enhance = puree<OuterProps>('DuoLayout')();

export default enhance(PureDuoLayout);
