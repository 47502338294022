import * as most from 'most';
import * as mostc from 'mostc';
import * as mA from 'most-adjunct';
import { select } from 'redux-most';
import {
  change,
  actionTypes,
} from 'redux-form';
import * as r from 'ramda';

import { headOr } from 'crosscutting/ramda';
import {
  getOptionGroups,
  getInvalidGroups,
} from 'domain/selectors/configurator';
import { Store } from 'redux';

const { CHANGE } = actionTypes;

export default () => (actions$: most.Stream<any>, store: Store): most.Stream<any> => r.pipe(
  select(CHANGE),
  mostc.filter(r.pathEq([ 'meta', 'form' ], 'usf.configurator')),
  mostc.delay(10),
  mA.switchMap(() => {
    const state = store.getState();
    const groups = getOptionGroups(state);
    const invalidGroups = getInvalidGroups(state);
    const getChanges = r.pipe(
      r.map(
        r.converge(
          r.objOf,
          [
            r.identity,
            r.curryN(1, r.pipe(
              r.propEq('groupId'),
              //@ts-ignore
              r.flip(r.find)(groups),
              r.prop('options'),
              headOr({}),
              // r.defaultTo([]),
              // r.head,
              r.prop('optionId'),
            )),
          ],
        ),
      ),
      r.mergeAll,
    );
    const changes = getChanges(invalidGroups);

    const actions = r.pipe(
      r.mapObjIndexed((value, key) => {
        return change('usf.configurator', btoa(key), value);
      }),
      r.values,
    )(changes);

    return most.from(actions);
  }),
)(actions$);
