import * as mostc from 'mostc';
import { select } from 'redux-most';
import * as r from 'ramda';

import { PAGE_CHANGED } from 'domain/messages/root';
import { fetch } from 'application/signals/ribbons';
import { getExperiencesConfig } from 'domain/selectors/ribbons/experiences';
import { EXPERIMENTS_COOKIE } from 'domain/constants/experiences';
import { setOptimizeGA } from './utils';
import { SendAnalytics } from '@ux/tagmg';

export default (
  dispatchGAEvent: SendAnalytics,
  dispatch: SendAnalytics,
  getCookie: (name: string) => string | null,
) => r.pipe(
  select(PAGE_CHANGED),
  // the page is initially fetched on mount
  // but the page changed message is also emitted on mount
  // so we can ignore the first one that comes through
  mostc.skip(1),
  mostc.filter(
    r.pathSatisfies(
      r.complement(r.startsWith('/configuration')),
      [ 'payload', 'page' ],
    ),
  ),
  (transient: any) => {
    const experimentationCookie = getCookie(EXPERIMENTS_COOKIE);
    const cookieExperiences = getExperiencesConfig(experimentationCookie);
    setOptimizeGA(dispatch, dispatchGAEvent, cookieExperiences?.experiment?.reporting);
    return transient;
  },
  mostc.map(fetch),
);
