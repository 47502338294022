import { Core } from 'core';
import { combineEpics } from 'redux-most';
import product from './product';
import promotion from './promotion';
import basket from './basket';

export default (deps: Core) => combineEpics([
  product(
    deps.cta.getProductData,
    deps.ecommerce,
  ),
  promotion(deps.ecommerce),
  basket(deps.ecommerce),
]);
