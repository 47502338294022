import React from 'react';
import { puree, GetInnerProps } from '@ux/pure-enhance';
import { withProps } from '@team-griffin/rehook';
import { WubLoader } from '@ux/penders';
import { useStyles } from '@team-griffin/stylr';
import { gu2px } from '@ux/theme-utils';
import getBreakpoints from 'presentation/utils/getBreakpoints';
import { getBackgroundColor, getTitleColor } from 'presentation/utils/colorway';
import findResponsiveItem from 'presentation/utils/findResponsiveItem';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import { Fit, RibbonProps } from 'domain/constants/ribbons';
import * as r from 'ramda';

interface OuterProps extends RibbonProps {
}

const mapFit = (fit: Fit) => r.cond([
  [ r.equals(Fit.FULLSCREEN), r.always({
    minHeight: '100vh',
  }) ],
  [ r.equals(Fit.AUTO), r.always({
    height: 'auto',
  }) ],
  [ isFinite, r.always({
    minHeight: fit,
  }) ],
  [ r.T, r.always({
    height: 'auto',
  }) ],
])(fit);

const stylesheet = (props: OuterProps & BreakpointProps) => {
  const {
    theme,
    colorway,
    layout,
  } = props;

  const {
    spacing,
  } = theme;

  const activeFit = findResponsiveItem(
    getBreakpoints(props),
    layout.fit,
  );

  return useStyles({
    default: {
      root: {
        position: 'relative',
        backgroundColor: getBackgroundColor(theme, colorway),
        minHeight: gu2px(20, spacing),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        flexBasis: 'auto',
        ...mapFit(activeFit),
      },
      loader: {
        color: getTitleColor(theme, colorway),
        height: gu2px(5, spacing),
      },
    },
  });
};

const enhance = puree<OuterProps>('RibbonPending')(
  withBreakpointsHook,
  withProps(stylesheet),
);

type InnerProps = GetInnerProps<typeof enhance>;

const PureRibbonPending = ({
  styles,
}: InnerProps) => {
  return (
    <section style={styles.root}>
      <div style={styles.loader}>
        <WubLoader
          size={styles.loader.height as number}
          color={styles.loader.color as string}
        />
      </div>
    </section>
  );
};

export default enhance(PureRibbonPending);
