import {
  createStore,
  applyMiddleware,
} from 'redux';
import createReducer from 'domain/reducers';
import { initialize } from 'application/signals/root';
import { createBrowserHistory } from 'history';
import {
  composeWithDevTools,
} from 'redux-devtools-extension/logOnlyInProduction';
import {
  routerMiddleware,
} from 'connected-react-router';
import { middleware as upmMiddleware } from '@pex/upm';
import { batchedSubscribe } from 'redux-batched-subscribe';
import debounce from 'lodash.debounce';

import createEpicMiddleware from 'application/epicMiddleware';
import createAppEpics from 'application/epics/composite';
import di from './di';


export default () => {
  const debounceNotify = debounce((notify: Function) => notify(), 10);

  const history = createBrowserHistory();
  const reducer = createReducer(history);
  const routerMiddlewareInstance = routerMiddleware(history);
  const infrastructure = di();
  // compose domain
  const epics = createAppEpics(infrastructure);
  // pass epics into epic middleware
  const epicMiddleware = createEpicMiddleware(epics);

  const store = createStore(
    reducer,
    composeWithDevTools(
      applyMiddleware(
        epicMiddleware,
        upmMiddleware,
        routerMiddlewareInstance,
      ),
      // @ts-ignore
      batchedSubscribe(debounceNotify),
    ),
  );

  return {
    store,
    history,
    init: () => store.dispatch(initialize()),
  };
};
