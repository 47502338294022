import { Core } from 'core';

import { combineEpics } from 'redux-most';

import fetch from './fetch';
import change from './change';
import submit from './submit';

export default (deps: Core) => combineEpics([
  fetch(deps.configurator.fetch),
  change(),
  submit(
    deps.basket.addToBasket,
    deps.navigation.navigate,
    deps.dispatchFBEvent,
  ),
]);
