import {
  Navigate,
} from 'core/navigation';
import {
  AddToBasket, ProductData,
} from 'core/basket';

import {
  createEpic,
} from '@ux/fabric';
import {
  KnowledgeType,
  signals as rkSignals,
} from '@ux/reactive-knowledge';
import {
  setSubmitSucceeded,
  setSubmitFailed,
} from 'redux-form';
import * as r from 'ramda';

import {
  SUBMIT,
  Submit,
} from 'application/signals/configurator';
import { getOrderProcessUrl } from 'domain/selectors/basket';
import {
  getBrandId, getCurrency,
} from 'domain/selectors/common';
import {
  getVariation,
  getSelectedAddonIds,
  getConfiguredProduct,
  getTotalPrice,
  getQuantity,
} from 'domain/selectors/configurator';
import { SendFBAnalytics } from '@ux/tagmg';

export default (
  addToBasket: AddToBasket,
  navigate: Navigate,
  dispatchFBEvent: SendFBAnalytics,
) => createEpic({
  signal: SUBMIT,
  process: (action: Submit, state: any) => {
    const brandId = getBrandId(state);
    const variation = getVariation(state);
    const addons = getSelectedAddonIds(state);
    const productId = r.prop('id', variation);
    const pricingTier = r.path([ 'payload', 'pricingTier' ], action) as string|number;
    const product = getConfiguredProduct(state);
    const quantity = getQuantity(state);
    const productData = {
      name: product.name,
      price: Number(getTotalPrice(state)),
      category: product.category,
    } as ProductData;
    const currency = getCurrency(state);
    dispatchFBEvent('AddToCart', {
      //eslint-disable-next-line camelcase
      content_ids: [ productId ],
      //eslint-disable-next-line camelcase
      content_name: productData.name,
      value: productData.price * quantity,
      quantity: quantity,
      //eslint-disable-next-line camelcase
      content_type: productData.category,
      currency: currency,
    });
    return addToBasket(
      brandId,
      productId,
      pricingTier,
      addons,
      quantity,
      productData,
    );
  },
  onSuccess: (data: any, action: any, state: any) => {
    setTimeout(() => {
      navigate(
        getOrderProcessUrl(state),
      );
    }, 1000);

    return setSubmitSucceeded('usf.configurator');
  },
  onFailure: () => [
    setSubmitFailed('usf.configurator'),
    rkSignals.add({
      type: KnowledgeType.ERK,
      message: 'usf.configurator.submit.failed',
    }),
  ],
});
