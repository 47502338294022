import { Core } from 'core';

import { combineEpics } from 'redux-most';

import desireCallbackEpic from './desireCallback';
import submitCallbackEpics from './submitCallback';
import validateEpic from './validate';

export default (deps: Core) => combineEpics([
  desireCallbackEpic(),
  submitCallbackEpics(deps.ribbons.callback.requestCallback),
  validateEpic,
]);
