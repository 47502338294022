import { VisibilitySet } from 'core/ribbons/primitives';
import { filterObjIndexed } from '@team-griffin/capra';
import { createSelector } from 'reselect';
import { useBrandConfig, selectors as wlSelectors } from '@ux/whitelabel';
import { useLanguage } from '@ux/language';
import { Type } from 'domain/constants/ribbons';
import * as r from 'ramda';
import { State } from 'domain/reducers/usf/ribbons';
import { showBasedOnExperiences } from './experiences';

const validTypes = r.values(Type);

const existsInList = r.flip(r.contains);

const getState: (state: any) => State = r.path([ 'usf', 'ribbons' ]);

export const getActiveVisibilitySets = createSelector<State, any, any>(
  getState,
  r.prop('visibilitySet'),
);

const testVisibilitySet = (
  activeSets: any[],
  set: VisibilitySet,
) => !set || set.every(
  existsInList(
    r.toPairs(activeSets),
  ),
);

export const isVisibilitySetActive = (
  visibilitySet: VisibilitySet
) => createSelector(
  getActiveVisibilitySets,
  (activeVisibilitySets) => testVisibilitySet(activeVisibilitySets, visibilitySet),
);

const getAllRibbons = createSelector(
  getState,
  r.prop('ribbons'),
);

export const getRibbons = (experiences?: string[]) => createSelector(
  [
    getAllRibbons,
    getActiveVisibilitySets,
  ],
  (allRibbons, activeSets) => {
    const ribbons = [];
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x < allRibbons.length; x++) {
      const ribbon = allRibbons[x];
      //we check experiences and see if ribbon will be appended to list.
      const ribbonExperiences = ribbon.props.experiences;
      const show = showBasedOnExperiences(ribbonExperiences, experiences);
      if (show) {
        const set = ribbon.props?.visibilitySet ?? [];
        const visible = testVisibilitySet(activeSets, set);
        const skipped = ribbon.props?.type === Type.SKIPPED;
        const unknown = validTypes.indexOf(ribbon.type as Type) < 0;

        ribbons.push({
          ...ribbon,
          props: {
            ...ribbon.props,
            skipped,
            unknown,
            visible,
            hidden: skipped || unknown || !visible,
          },
        });
      }
    }
    return ribbons;
  },
);

export const getRibbon = (id: string) => createSelector(
  getRibbons(),
  r.find(r.propEq('id', id)),
);

export const splitRibbons = (props: {
  ribbons: any[],
  isMinSm: boolean,
  isMinMd: boolean,
}): [ any[], any[] ] => {
  const ribbons = props.ribbons;
  const i = ribbons.findIndex((ribbon) => {
    if (ribbon.type === Type.TERMINATE_FOLD) {
      if (props.isMinMd) {
        return ribbon.props.terminate.desktop;
      } else if (props.isMinSm) {
        return ribbon.props.terminate.tablet;
      }
      return ribbon.props.terminate.mobile;
    }
  });

  if (i < 0) {
    return [ [], ribbons ];
  }
  return r.splitAt(i, ribbons);
};

export const isInitialized = createSelector(
  getState,
  r.prop('initialized'),
);

export const hasPageLoadFailed = createSelector(
  getState,
  r.prop('pageLoadFailed'),
);

// we want to get the avilable languages from the CMS
// then the languages the brand officially supports (from BPT)
// the CMS languages look something like { en: '/en/foo', de: '/de/foo' }
// then we want to filter out the unsupported langauges from that object
export const getLanguageMap = createSelector<any, any, any>(
  [
    createSelector(
      getState,
      r.path([ 'language', 'languages' ]),
    ),
    createSelector(
      wlSelectors.getBrandConfig,
      r.propOr([], 'languages'),
    ),
  ],
  (availableLanguages, supportedLanguages) => {
    return filterObjIndexed(
      r.pipe(
        r.nthArg(1),
        r.contains(r.__, supportedLanguages),
      ),
      availableLanguages,
    );
  },
);

export const getPageStatus = createSelector(
  getState,
  r.path([ 'language', 'statusCode' ]),
) as (data: State)=> number;

export const getPagePath = createSelector(
  getState,
  r.path([ 'language', 'path' ]),
) as (data: State)=> string;
export const getCurrentLanguage = createSelector(
  getState,
  r.path([ 'language', 'language' ]),
);

export const useLocales = () => {
  const brandConfig = useBrandConfig();
  const { data: language } = useLanguage();

  return [ language ].concat(brandConfig.languages);
};

export const getTrustpilotPageId = createSelector(
  wlSelectors.getBrandConfig,
  r.path([ 'trustPilot', 'pageId' ]),
);

export const getAklamioId = createSelector<any, any, any>(
  wlSelectors.getBrandConfig,
  r.prop('aklamioId'),
);

export const getRibbonAnalytics = (id: string) => createSelector(
  getRibbons(),
  r.pipe(
    r.find(r.propEq('id', id)),
    r.path([ 'props', 'analytics' ]),
  ),
);