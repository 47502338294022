import {
  Group,
  GroupResponse,
  Option,
  OptionResponse,
  Product,
  ProductResponse,
  VariationResponse,
  VariationOptionResponse,
  ResourceResponse,
  Spec,
  Response,
  View,
  Addon,
  AddonResponse,
} from 'core/configurator';

import { schema, normalize } from 'normalizr';
import * as r from 'ramda';
import {
  getVariationOptions,
  getIcon,
} from './utils';

const { Entity } = schema;

export const specSchema = new Entity(
  'specs',
  {},
  {
    idAttribute: 'attribute',
    processStrategy: (data: ResourceResponse): { spec: Spec } => ({
      spec: data,
    }),
  },
);

export const productSchema = new Entity(
  'product',
  {
    specs: [ specSchema ],
  },
  {
    processStrategy: (data: ProductResponse): Product => ({
      id: data.id,
      name: data.displayName,
      price: {
        net: r.pathOr(
          r.path(
            [ 'prices', 'retail', 'net' ],
            data,
          ),
          [ 'prices', 'retail', 'net', 'amount' ],
          data,
        ),
        tax: r.pathOr(
          r.path(
            [ 'prices', 'retail', 'tax' ],
            data,
          ),
          [ 'prices', 'retail', 'tax', 'amount' ],
          data,
        ),
        gross: r.pathOr(
          r.path(
            [ 'prices', 'retail', 'gross' ],
            data,
          ),
          [ 'prices', 'retail', 'gross', 'amount' ],
          data,
        ),
        currency: r.path(
          [ 'prices', 'retail', 'gross', 'currency' ],
          data,
        ),
      },
      specs: data.resources,
      // After PPE sku change these variables will be available on the product
      // until then they will be undefined but we fix this in the selector (getting them from variations)
      category: data.categoryName,
      paymentInterval: data.defaultPaymentInterval,
      term: data.defaultMinTerm,
    }),
  },
);

export const variantLinkSchema = new Entity(
  'variantLinks',
  {},
  {
    idAttribute: (
      data: VariationOptionResponse & { attributeName: string },
    ) => `${data.attributeName}.${data.attributeValue}`,
    processStrategy: (
      data: VariationOptionResponse & { attributeName: string },
    ) => {
      return {
        id: `${data.attributeName}.${data.attributeValue}`,
        groupId: data.attributeName,
        optionId: data.attributeValue,
      };
    },
  },
);

export const variationSchema = new Entity(
  'variations',
  {
    options: [ variantLinkSchema ],
  },
  {
    processStrategy: (data: VariationResponse, parent: Response) => {
      const id = data.id;

      const options = getVariationOptions(
        parent.options,
        //@ts-ignore
        data.productOptionValues,
      );
      // categoryName is used to augment product. Following PPE change to reduce additional sku info we will get it from
      // product directly and it will be undefined here (can be removed at a later date)
      const category = data.categoryName;
      // eslint-disable-next-line max-len
      const currency = data.defaultPrice ? data.defaultPrice.retailPrice.gross.currency : data.retailPrice.currency;

      return {
        id,
        category,
        // paymentInterval and term are used to augment product. Following PPE change to reduce additional sku info
        // we will get this directly from the product and it will be undefined here (can be removed at a later date)
        paymentInterval: data.defaultPaymentInterval,
        term: data.defaultMinTerm,
        options,
        currency,
      };
    },
  },
);

export type OptionSchemaResponse = OptionResponse & {
  groupId: string,
  parentMeta: Object,
};
export const optionSchema = new Entity(
  'options',
  {
  },
  {
    idAttribute: (data) => `${data.groupId}.${data.attributeValue}`,
    processStrategy: (data: OptionSchemaResponse): Option => ({
      id: `${data.groupId}.${data.attributeValue}`,
      groupId: data.groupId,
      optionId: data.attributeValue,
      icon: getIcon(data),
      name: data.attributeValue,
      //@ts-ignore
      priceAdjustment: r.pipe(
        r.defaultTo({}),
        r.map(r.prop('amount')),
      )(data.priceAdjustment),
      productId: data.productOptionId,
      meta: data.metadata,
      currency: data.priceAdjustment.gross.currency,
    }),
  },
);

export const addonSchema = new Entity(
  'addons',
  {},
  {
    idAttribute: 'id',
    processStrategy: (data: AddonResponse): Addon => ({
      id: data.id,
      groupId: data.id,
      optionId: data.id,
      productId: data.id,
      icon: 'tick',
      categoryName: data.categoryName,
      name: data.displayName,
      priceAdjustment: {
        net: data.defaultPrice.retailPrice.net.amount,
        gross: data.defaultPrice.retailPrice.gross.amount,
        tax: data.defaultPrice.retailPrice.tax.amount,
      },
      currency: data.defaultPrice.retailPrice.gross.currency,
    }),
  },
);

export const groupScema = new Entity(
  'groups',
  {
    options: [ optionSchema ],
  },
  {
    idAttribute: 'attributeName',
    processStrategy: (data: GroupResponse): Group => ({
      id: data.attributeName,
      groupId: data.attributeName,
      label: data.label,
      description: data.description,
      options: r.map(
        r.pipe(
          r.assoc('groupId', data.attributeName),
          r.assoc('parentMeta', data.metadata),
        ),
        data.allowedValues,
      ),
    }),
  },
);

const viewSchema = {
  options: [ groupScema ],
  variations: [ variationSchema ],
  addons: [ addonSchema ],
  product: productSchema,
};

export default (data: Response): View => {
  //@ts-ignore
  return r.pipe(
    (data: Response) => normalize(data, viewSchema),
    r.prop('entities'),
    r.merge({
      groups: {},
      options: {},
      variations: {},
      variantLinks: {},
      addons: {},
      specs: {},
      product: {},
    }),
    r.over(
      r.lensProp('product'),
      r.pipe(
        //@ts-ignore
        r.values,
        r.head,
      ),
    ),
    r.over(
      r.lensProp('specs'),
      r.map(r.prop('spec')),
    ),
  )(data);
};
