import { PageCache } from '../utils/pageCache';
import { ClearCache } from 'core/cache';
import jpex from 'jpex';

const clearCache = (pageCache: PageCache): ClearCache => {
  const clearCache = () => {
    pageCache.clear();
  };
  jpex.factory<ClearCache>(() => clearCache);
  return clearCache;
};

export default clearCache;
