/* eslint-disable react/jsx-no-bind */
import React, { Fragment, lazy, Suspense, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Route,
  Switch,
  useLocation,
} from 'react-router';
import ConnectedReactiveKnowledge from '@ux/reactive-knowledge';

import { ModalRoute } from '@team-griffin/redux-modal-router';
import CMPage from './pages/CMPage';
import { puree, GetInnerProps } from '@ux/pure-enhance';
import { Theme } from '@ux/theme-utils';
import { useCloseDropdown } from 'react-dropdown-router';
import { tap, withProps } from '@team-griffin/rehook';

const ConfiguratorPage = lazy(() => import('./pages/ConfiguratorPage'));
const Modal = lazy(() => import('@ux/modal'));
const VideoModal = lazy(() => import('components/modal/Video'));

interface OuterProps {
  theme: Theme,
}

const enhance = puree<OuterProps>('Core')(
  withProps(() => ({
    intl: useIntl(),
  })),
  tap(() => {
    // whenever we change pages we should close all dropdowns
    const { pathname } = useLocation();
    const closeDropdown = useCloseDropdown();
    useEffect(closeDropdown, [ pathname, closeDropdown ]);
  }),
);

type InnerProps = GetInnerProps<typeof enhance>;

const PureCore = ({
  theme,
  ...props
}: InnerProps) => (
  <Fragment>
    <Switch>
      <Route
        path="/configuration"
        exact={true}
        render={() => (
          <Suspense fallback={(<div/>)}>
            <ConfiguratorPage
              theme={theme}
              {...props}
            />
          </Suspense>
        )}
      />
      <Route
        path="/"
        exact={false}
        render={() => (
          <CMPage
            theme={theme}
            {...props}
          />
        )}
      />
    </Switch>
    <ConnectedReactiveKnowledge
      theme={theme}
    />
    <ModalRoute
      path="PLAY_VIDEO"
      // eslint-disable-next-line react/jsx-no-bind
      render={(modalProps: any) => (
        <Suspense fallback={(<div/>)}>
          <Modal
            theme={theme}
            /* eslint-disable-next-line react/jsx-handler-names */
            onRequestClose={modalProps.closeModal}
            isOpen={true}
          >
            <VideoModal
              theme={theme}
              actions={modalProps}
            />
          </Modal>
        </Suspense>
      )}
    />
  </Fragment>
);

export default enhance(PureCore);
