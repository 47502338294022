import { createAction, Action } from 'redux-actions';
import { payloadCreator } from '@ux/fabric';

const prefix = '@@usf/configurator/S_';
export const FETCH = `${prefix}FETCH`;
export const SUBMIT = `${prefix}SUBMIT`;

export type Fetch = Action<{
  productId: number,
}>;
export type Submit = Action<{
  pricingTier: number|string,
}>;

export const fetch = createAction(
  FETCH,
  payloadCreator([ 'productId' ])
);
export const submit = createAction(
  SUBMIT,
  payloadCreator([ 'pricingTier' ])
);
