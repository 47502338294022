import { createSelector } from 'reselect';
import * as r from 'ramda';
import { getCategories } from '@pex/upm';
import { selectors as wlSelectors } from '@ux/whitelabel';

const { getBrandConfig } = wlSelectors;

const getState = r.path([ 'usf', 'root' ]);

export const getCurrentPage = createSelector(
  getState,
  r.prop('page'),
);

export const getTealiumCategories: (state: any) => string[] = r.pipe(
  getCategories,
  r.map(
    r.when(
      r.equals('advertising'),
      r.always('marketing'),
    ),
  ),
);

export const getSupportedLanguages = createSelector(
  getBrandConfig,
  r.propOr([], 'languages'),
);

export const getPrivacyPolicyUrl = r.pipe(
  wlSelectors.getUrl('legal.privacy'),
  r.defaultTo('/privacy-policy'),
);
