import { CreateDelayedQueue } from '@ux/queue';

import _addToBasket from './add';
import _addDomainToBasket from './addDomain';
import _applyVoucher from './applyVoucher';
import { Middleware } from '@ux/drivers';

export default (
  middleware: Middleware,
  createQueue: CreateDelayedQueue,
) => {
  return {
    addToBasket: _addToBasket(middleware, createQueue),
    addDomainToBasket: _addDomainToBasket(middleware, createQueue),
    applyVoucher: _applyVoucher(middleware),
  };
};
