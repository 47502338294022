import { createAction, Action } from 'redux-actions';

const prefix = '@@usf/website-report-start/S_';
export const SUBMIT = `${prefix}SUBMIT`;

export type Submit = Action<{
    domain: string,
    destination: string,
    form: string,
    ribbonType: string,
    ribbonName: string,
}>;

export const submit = createAction(SUBMIT);
