import { Navigate } from 'core/navigation';
import { createEpic } from '@ux/fabric';
import {
  EXTERNAL_PUSH,
  ExternalPush,
} from 'application/signals/root';

const externalPushEpic = (navigate: Navigate) => createEpic({
  signal: EXTERNAL_PUSH,
  process: (action: ExternalPush) => {
    navigate(action.payload.url);
  },
});

export default externalPushEpic;
