import { createAction, Action } from 'redux-actions';
import { payloadCreator } from '@ux/fabric';

const prefix = '@@usf/root/S_';
export const INITIALIZE = `${prefix}INITIALIZE`;
export const SCOUT = `${prefix}SCOUT`;
export const EXTERNAL_PUSH = `${prefix}EXTERNAL_PUSH`;

export type Initialize = Action<{}>;
export type Scout = Action<{}>;
export type ExternalPush = Action<{
  url: string,
}>;

export const initialize: () => Initialize = createAction(INITIALIZE);
export const scout = createAction(SCOUT);
export const externalPush = createAction(
  EXTERNAL_PUSH,
  payloadCreator([ 'url' ]),
);
