import * as r from 'ramda';
import {
  fetch,
  ndjson,
  xray,
} from '@ux/drivers';

export default () => r.pipe(
  xray(),
  ndjson(),
)(fetch);