import { createEpic } from '@ux/fabric';
import { PROMOTION_CLICKED } from 'application/signals/cta';
import { promoNameToPromoId } from 'domain/logic/promotion';
import { Action } from 'redux-actions';

const productEpic = (
  ecommerce: Function,
) => createEpic({
  signal: PROMOTION_CLICKED,
  process: (action: Action<any>) => {
    const {
      payload: {
        promotion,
      },
    } = action;

    if (!promotion) {
      return;
    }

    const promoId = promoNameToPromoId(promotion);

    ecommerce([
      [
        'addPromo',
        {
          id: promoId,
          name: promotion,
        },
      ],
      [
        'setAction',
        'promo_click',
      ],
    ]);
  },
});

export default productEpic;
