import { Ribbon } from 'core/ribbons';
import { VisibilitySet } from 'core/ribbons/primitives';

import { createAction, Action } from 'redux-actions';

const prefix = '@@usf/ribbons/M_';
export const FETCHING = `${prefix}FETCHING`;
export const RIBBON_FETCHED = `${prefix}RIBBON_FETCHED`;
export const RIBBONS_PREFETCHED = `${prefix}RIBBONS_PREFETCHED`;
export const RIBBONS_FETCHED = `${prefix}RIBBONS_FETCHED`;
export const RIBBONS_FAILED = `${prefix}RIBBONS_FAILED`;
export const SET_VISIBILITY = `${prefix}SET_VISIBILITY`;
export const PAGE_LANGUAGE_FETCHED = `${prefix}PAGE_LANGUAGE_FETCHED`;

export type Fetching = Action<{}>;
export type RibbonsPreFetched = Action<Ribbon[]>;
export type RibbonFetched = Action<Ribbon>;
export type RibbonsFetched = Action<{}>;
export type RibbonsFailed = Action<{
  // eslint-disable-next-line max-len
  error: 'PageNotFoundCmsError' | 'NoRibbonsFoundCmsError' | 'NotFoundCmsError' | 'CmsMissingFieldsError' | 'CmsError' | string,
  message: string,
}>;
export type SetVisibility = Action<VisibilitySet>;
export type PageLanguageFetched = Action<{
  language: string,
  languages: {
    [key: string]: string,
  },
  path: string,
  statusCode: number,
}>;

export const fetching = createAction(FETCHING);
export const ribbonFetched = createAction(RIBBON_FETCHED);
export const ribbonsPrefetched = createAction(RIBBONS_PREFETCHED);
export const ribbonsFetched = createAction(RIBBONS_FETCHED);
export const ribbonsFailed = createAction(RIBBONS_FAILED);
export const setVisibility = createAction(SET_VISIBILITY);
export const pageLanguageFetched = createAction(PAGE_LANGUAGE_FETCHED);
