import React, { useMemo, lazy, Suspense, useEffect } from 'react';
import { withProps } from '@team-griffin/rehook';
import { puree, GetInnerProps } from '@ux/pure-enhance';
import { useTheme } from '@ux/theme-utils';
import { RibbonProps, Type } from 'domain/constants/ribbons';
import { RibbonRaidType } from './RibbonRaid';
import {
  componentAdapterMap,
  importRibbon,
  getNextRibbon,
  isDuoLayoutStart,
  isSkippedRibbon,
  isDuoLayoutEnd,
} from './utils';
import DuoLayout from './DuoLayout';
import RibbonPending from '../ribbons/Ribbon/RibbonPending';

interface OuterProps {
  ribbon: RibbonRaidType,
  ribbons: RibbonRaidType[],
  index: number,
}

const enhance = puree<OuterProps>('RibbonRaidRibbon')(
  withProps(({
    ribbon,
    index,
    ribbons,
  }) => {
    const componentAdapter = useMemo(() => componentAdapterMap[ribbon.type], [ ribbon.type ]);
    const ribbonProps = useMemo(
      () => componentAdapter(ribbon),
      [ componentAdapter, ribbon ],
    ) as RibbonProps;
    const showPending = ribbon.type !== Type.META;
    const nextRibbon = useMemo(() => getNextRibbon(index, ribbons), [ index, ribbons ]);
    const LazyComponent = useMemo(() => {
      return lazy(() => importRibbon(ribbon.type));
    }, [ ribbon.type ]);

    useEffect(() => {
      if (isDuoLayoutStart(ribbon) || isDuoLayoutEnd(ribbon) || isSkippedRibbon(ribbon)) {
        return;
      }
      if (ribbon.ready) {
        return;
      }
      importRibbon(ribbon.type);
    }, [ ribbon ]);

    return {
      ribbonProps,
      showPending,
      nextRibbon,
      LazyComponent,
    };
  }),
  withProps(useTheme),
);

type InnerProps = GetInnerProps<typeof enhance>;

const PureRibbon = ({
  ribbon,
  theme,
  ribbonProps,
  nextRibbon,
  LazyComponent,
  showPending,
}: InnerProps) => (
  <Choose>
    <When condition={isDuoLayoutStart(ribbon)}>
      <DuoLayout
        theme={theme}
        ribbon={ribbon}
        ribbonProps={ribbonProps}
        nextRibbon={nextRibbon}
      />
    </When>
    <When condition={isDuoLayoutEnd(ribbon)}>
      {null}
    </When>
    <When condition={isSkippedRibbon(ribbon)}>
      {null}
    </When>
    <When condition={!ribbon.ready}>
      <RibbonPending
        theme={theme}
        {...ribbonProps}
      />
    </When>
    <Otherwise>
      <Suspense
        fallback={showPending && (
          <RibbonPending
            theme={theme}
            {...ribbonProps}
          />
        )}
      >
        <LazyComponent
          id={ribbon.id}
          theme={theme}
          {...ribbonProps}
        />
      </Suspense>
    </Otherwise>
  </Choose>
);

export default enhance(PureRibbon);
