import { select } from 'redux-most';
import * as r from 'ramda';
import { change } from 'redux-form';
import * as mostc from 'mostc';

import {
  DESIRE_CALLBACK,
  DesireCallback,
} from 'application/signals/ribbons/callback';

export default () => r.pipe(
  select(DESIRE_CALLBACK),
  mostc.map((action: DesireCallback) => {
    return change(action.meta.form, '__desired__', true);
  }),
);
