import * as rA from 'ramda-adjunct';
import * as r from 'ramda';
import {
  curry2,
  isNotNilOrEmpty,
} from '@team-griffin/capra';

const {
  isNotEmpty,
  isNotNil,
  mapIndexed,
} = rA;

export {
  curry2,
  isNotNilOrEmpty,
  isNotEmpty,
  isNotNil,
  mapIndexed,
};

export const headOr = r.curryN(2, (fallback: any, arr: any[]) => r.pipe(
  r.defaultTo([]),
  r.head,
  r.defaultTo(fallback),
)(arr));

export const getFirstKey = r.pipe(
  r.keys,
  r.head,
) as (data: any)=>string;

export const getFirstValue = r.pipe(
  //@ts-ignore
  r.values,
  r.head,
);

export const mapKeys = r.curryN(2, (fn: any, obj: any) => r.pipe(
  r.defaultTo({}),
  r.mapObjIndexed(
    r.converge(
      r.unapply(r.identity),
      [
        r.pipe(
          r.nthArg(1),
          fn,
        ),
        r.identity,
      ]
    ),
  ),
  r.values,
  r.fromPairs,
)(obj));
