import { after } from '@ux/promise';
import {
  IsPageLoaderDestroyed,
  NotifyScout,
} from 'core/root';

import * as r from 'ramda';

export const isPageLoaderDestroyed = (
  document: Document,
): IsPageLoaderDestroyed => r.pipe(
  r.invoker(1, 'getElementsByClassName')('page-loader'),
  (collection) => Array.prototype.slice.call(collection),
  r.isEmpty,
).bind(null, document);

export const notifyScout = (
  document: Document
): NotifyScout => (status, path) => after(1000).then(() => {
  const event = document.createEvent('CustomEvent');
  const url = r.unless(
    r.isNil,
    (path) => `${window.location.protocol}//${window.location.host}${path}`,
  )(path);
  event.initCustomEvent('@scout/done', false, false, {
    statusCode: status,
    url,
  });
  document.dispatchEvent(event);
  // scout doesn't always pick up on this event
  // especially when usf auto-redirects when fetching
  // so we also log to the console and scout will check the console
  // eslint-disable-next-line no-console
  console.log(`@scout/done/${status}`);
});
