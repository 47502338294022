import { createEpic } from '@ux/fabric';
import * as r from 'ramda';
import * as rA from 'ramda-adjunct';

import {
  ADDED_TO_BASKET,
  VOUCHER_APPLIED,
  VoucherApplied,
  AddedToBasket,
} from 'domain/messages/basket';
import { getOrderProcessUrl } from 'domain/selectors/basket';
import { getPushAction } from 'application/utils';

const postActionEpic = () => createEpic({
  signal: [ ADDED_TO_BASKET, VOUCHER_APPLIED ],
  filter: r.pathSatisfies(
    rA.contained([ 'NAVIGATE', 'CHECKOUT' ]),
    [ 'payload', 'postAction', 'type' ],
  ),
  process: (action: AddedToBasket | VoucherApplied, state: any): string => {
    return r.cond([
      [
        r.propEq('type', 'CHECKOUT'),
        () => getOrderProcessUrl(state),
      ],
      [
        r.propEq('type', 'NAVIGATE'),
        r.prop('url'),
      ],
    ])(action.payload.postAction);
  },
  onSuccess: (url: string) => {
    return getPushAction(url);
  },
});

export default postActionEpic;
