/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import { puree, GetInnerProps } from '@ux/pure-enhance';
import ImpersonationRibbon from '@ux/impersonation-ribbon';
import Core from './Core';
import Layout from './Layout';
import Head from './Head';
import { PrivacyRibbon } from '@pex/upm';
import { ShimmerProvider } from '@ux/shimmer';
import { Theme } from '@ux/theme-utils';
import ReactQueryDevtools from './ReactQueryDevtools';
import { DropdownRouter } from 'react-dropdown-router';

interface OuterProps {
  theme: Theme,
  categories: string[],
  externalRoutes: string[],
  pagePath: string,
  pageStatus: string,
  currentLanguage: string,
  languageMap: object,
  supportedLanguages: string[],
  privacyPolicyUrl: string,
  market: string,
}

const enhance = puree<OuterProps>('App')();

type InnerProps = GetInnerProps<typeof enhance>;


const App = ({
  theme,
  categories,
  externalRoutes,
  pagePath,
  pageStatus,
  currentLanguage,
  languageMap,
  supportedLanguages,
  privacyPolicyUrl,
  market,
  ...props
}: InnerProps) => (
  <Fragment>
    <ReactQueryDevtools/>
    <ImpersonationRibbon/>
    <PrivacyRibbon
      theme={theme}
      categories={categories}
      privacyPolicyUrl={privacyPolicyUrl}
      cookiesToPreserve={[ 'brand_id', 'market', 'auth_idp', 'info_idp', 'ux-language-preferred' ]}
      market={market}
    />
    <DropdownRouter>
      <Head
        theme={theme}
        currentLanguage={currentLanguage}
        supportedLanguages={supportedLanguages}
        languageMap={languageMap}
        pagePath={pagePath}
        pageStatus={pageStatus}
      />
      <Layout
        theme={theme}
        externalRoutes={externalRoutes}
        languageMap={languageMap}
      >
        <ShimmerProvider>
          <Core
            theme={theme}
            {...props}
          />
        </ShimmerProvider>
      </Layout>
    </DropdownRouter>
  </Fragment>
);
export default enhance(App);
