import * as r from 'ramda';
import * as rA from 'ramda-adjunct';

const getFuzzyLang = r.pipe(
  r.split('-'),
  r.head,
);
const combineKeyValues = (keyKey, valueKey) => r.pipe(
  r.mapObjIndexed((value, key) => ({
    [keyKey]: key,
    [valueKey]: value,
  })),
  r.values,
);

const groupByLang = r.pipe(
  r.groupBy(
    r.pipe(
      r.prop('lang'),
      getFuzzyLang,
    ),
  ),
  r.values,
);

const setFullLang = r.map(
  r.map(
    r.converge(
      r.assoc('full'),
      [
        r.prop('lang'),
        r.identity,
      ],
    ),
  ),
);

const setFuzzyLang = r.over(
  r.lensProp('lang'),
  getFuzzyLang,
);

const getLanguages = r.converge(
  (languages, defaultLanguage) => r.prepend(
    r.pipe(
      r.find(r.propEq('full', defaultLanguage)),
      r.merge(r.__, {
        lang: 'x-default',
        full: 'x-default',
      }),
    )(languages),
    languages,
  ),
  [
    r.pipe(
      r.propOr({}, 'languageMap'),
      combineKeyValues('lang', 'url'),
      groupByLang,
      setFullLang,
      r.map(
        r.when(
          rA.lengthEq(1),
          r.map(setFuzzyLang),
        ),
      ),
      r.flatten,
    ),
    r.path([ 'supportedLanguages', '0' ]),
  ],
);

export default getLanguages;
