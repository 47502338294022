import React, { Fragment } from 'react';
import { withProps } from '@team-griffin/rehook';
import { puree, GetInnerProps } from '@ux/pure-enhance';
import stylesheet from './RibbonRaid.stylesheet';
import { RibbonProps, Type } from 'domain/constants/ribbons';
import RibbonSet from './RibbonSet';
import FaqJsonLd from '../FaqJsonLd';

export interface AnyRibbonProps extends RibbonProps {
  [key: string]: any,
}
export interface RibbonRaidType {
  props: AnyRibbonProps,
  type: Type,
  id: string,
  ready: boolean,
}

export interface OuterProps {
  leadingHeightOffset: number,
  leadingRibbons: RibbonRaidType[],
  ribbons: RibbonRaidType[],
  allRibbons: RibbonRaidType[],
}

const enhance = puree<OuterProps>('RibbonRaid')(
  withProps((props) => ({
    hasLeadingRibbons: props.leadingRibbons.length > 0,
  })),
  withProps((props) => stylesheet(props)),
);

type InnerProps = GetInnerProps<typeof enhance>;

const PureRibbonRaid = ({
  styles,
  leadingRibbons,
  ribbons,
  hasLeadingRibbons,
  allRibbons,
}: InnerProps) => (
  <Fragment>
    <FaqJsonLd
      ribbons={allRibbons}
    />
    <If condition={hasLeadingRibbons}>
      <div style={styles.root}>
        <RibbonSet
          allRibbons={allRibbons}
          offset={0}
          ribbons={leadingRibbons}
        />
      </div>
    </If>
    <RibbonSet
      allRibbons={allRibbons}
      offset={leadingRibbons.length}
      ribbons={ribbons}
    />
    <div id="spaq-availability"/>
  </Fragment>
);

export default enhance(PureRibbonRaid);

// eslint-disable-next-line no-underscore-dangle
export const __test__ = {
  PureRibbonRaid,
};
