import { createAction, Action, ActionMeta } from 'redux-actions';
import { payloadCreator } from '@ux/fabric';
import * as r from 'ramda';

const prefix = '@@usf/basket/M_';
export const ADDING_TO_BASKET = `${prefix}ADDING_TO_BASKET`;
export const ADDED_TO_BASKET = `${prefix}ADDED_TO_BASKET`;
export const ADD_TO_BASKET_FAILED = `${prefix}ADD_TO_BASKET_FAILED`;
export const ADD_TO_BASKET_RESET = `${prefix}ADD_TO_BASKET_RESET`;
export const APPLYING_VOUCHER = `${prefix}APPLYING_VOUCHER`;
export const VOUCHER_APPLIED = `${prefix}VOUCHER_APPLIED`;
export const VOUCHER_FAILED = `${prefix}VOUCHER_FAILED`;
export const VOUCHER_RESET = `${prefix}VOUCHER_RESET`;

export type AddingToBasket = Action<{
  buttonId: string,
  productId: string,
}>;
export type AddedToBasket = ActionMeta<{
  buttonId: string,
  productId: string,
  postAction: {
    type: string,
    url: string,
  },
  pricingTier?: any,
  silent: boolean,
}, {
  pending: Function,
  resolve: Function,
}>;
export type AddToBasketFailed = ActionMeta<Error, {
    buttonId: string,
    productId: string,
    reject: Function
}>;
export type AddToBasketReset = Action<{
  buttonId: string,
  productId: string,
}>;
export type ApplyingVoucher = Action<{
  buttonId: string,
  voucherCode: string,
}>;
export type VoucherApplied = Action<{
  buttonId: string,
  voucherCode: string,
  postAction: {
    type: string,
    url: string,
  },
}>;
export type VoucherFailed = ActionMeta<Error, {
  buttonId: string,
  voucherCode: string,
}>;
export type VoucherReset = Action<{
  buttonId: string,
  voucherCode: string,
}>;

export const addingToBasket = createAction(
  ADDING_TO_BASKET,
  payloadCreator([ 'buttonId', 'productId' ]),
) as any;
export const addedToBasket = createAction(
  ADDED_TO_BASKET,
  payloadCreator([ 'buttonId', 'productId', 'postAction', 'fqdn', 'silent' ]),
  //@ts-ignore
  payloadCreator([ 'resolve' ], 5),
) as any;
export const addToBasketFailed = createAction(
  ADD_TO_BASKET_FAILED,
  r.nthArg(0),
  payloadCreator([ 'buttonId', 'productId', 'reject' ], 1),
);
export const addToBasketReset = createAction(
  ADD_TO_BASKET_RESET,
  payloadCreator([ 'buttonId', 'productId' ]),
);
export const applyingVoucher = createAction(
  APPLYING_VOUCHER,
  payloadCreator([ 'buttonId', 'voucherCode' ]),
) as any;
export const voucherApplied = createAction(
  VOUCHER_APPLIED,
  payloadCreator([ 'buttonId', 'voucherCode', 'postAction' ]),
) as any;
export const voucherFailed = createAction(
  VOUCHER_FAILED,
  r.nthArg(0),
  payloadCreator([ 'buttonId', 'voucherCode' ], 1),
);
export const voucherReset = createAction(
  VOUCHER_RESET,
  payloadCreator([ 'buttonId', 'voucherCode' ]),
);
