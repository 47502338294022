import React, { CSSProperties } from 'react';
import {
  withProps,
} from '@team-griffin/rehook';
import { useStyles } from '@team-griffin/stylr';
import { Container } from '@ux/grid';
import Message from '@ux/message';
import PaddingBox from '@ux/padding-box';
import { Button, Kind } from '@ux/button';
import Glyph from '@ux/glyphs';
import {
  percentage,
} from '@ux/style-utils';
import {
  headingXl,
} from '@ux/type-size';
import { gu2px, Theme } from '@ux/theme-utils';
import pureEnhance from '@ux/pure-enhance';

interface OuterProps {
  theme: Theme,
  onRetry: (evt: any) => void,
}
interface InnerProps extends OuterProps {
  styles: {
    root: CSSProperties,
    spacer: CSSProperties,
  }
}
const stylesheet = ({
  theme: {
    typography,
    spacing,
  },
}: InnerProps) => useStyles({
  default: {
    root: {
      width: percentage(100),
      maxWidth: 800,
      height: percentage(100),
      margin: '0 auto',
      minHeight: 400,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      ...headingXl(typography),
    },
    spacer: {
      height: gu2px(3, spacing),
    },
  },
});

const PureLoadFailed = ({
  styles,
  theme,
  onRetry,
}: InnerProps) => (
  <Container>
    <PaddingBox
      theme={theme}
      style={styles.root}
    >
      <Glyph
        size={gu2px(6, theme.spacing)}
        icon="circleExclamation"
        color={theme.palette.grey04}
      />
      <div style={styles.spacer}/>
      <Message id="usf.page.failed"/>
      <div style={styles.spacer}/>
      <Button
        type="button"
        onClick={onRetry}
        kind={Kind.SECONDARY}
      >
        <Message id="usf.page.retry"/>
      </Button>
    </PaddingBox>
  </Container>
);

const enhance = pureEnhance<InnerProps, OuterProps>(
  'LoadFailed', [
    withProps(stylesheet),
  ]
);

export default enhance(PureLoadFailed);
