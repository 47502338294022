import _requestCallback from './requestCallback';
import { Middleware } from '@ux/drivers';

export default (
  middleware: Middleware,
) => {
  return {
    requestCallback: _requestCallback(middleware),
  };
};
