import { createEpic } from '@ux/fabric';
import { initialize, stopSubmit, getFormValues } from 'redux-form';
import {
  SUBMIT,
  Submit,
} from 'application/signals/ribbons/website-report-start';

const submitEpic = () => createEpic({
  signal: SUBMIT,
  process: (action: Submit, state) => {
    const formValues = getFormValues(action.payload.form)(state) as { domain: string };
    const domain = formValues?.domain;
    const target = `${action.payload.destination.replace('{{url}}', encodeURIComponent(domain))}`;
    window.open(target, '_blank');

    return [];
  },
  onSuccess: (x, action: Submit) => {
    return [
      stopSubmit(action.payload.form),
      initialize(action.payload.form, {}, true),
    ];
  },
});

export default submitEpic;
