import { History, GetPath } from 'core/navigation';
import { createEpic } from '@ux/fabric';
import {
  UPDATE_QUERY_PARAM,
  UpdateQueryParam,
} from 'application/signals/ribbons/domain-search';
import * as r from 'ramda';

const updateQueryParamEpic = (
  getPath: GetPath,
  history: History,
) => createEpic({
  signal: UPDATE_QUERY_PARAM,
  process: (action: UpdateQueryParam) => {
    const path = getPath();
    const query = r.pathOr('', [ 'payload', 'query' ], action);

    r.ifElse(
      r.isEmpty,
      () => history.replaceState({}, '', path),
      () => history.replaceState({}, '', `${path}?domain=${query}`)
    )(query);
  },
});

export default updateQueryParamEpic;
