import { PageCache } from '../../utils/pageCache';

import * as r from 'ramda';
import * as most from 'most';
import { FetchType } from 'domain/constants/ribbons';

export type ProcessRibbonFinished = (path: string) => (data: Object) => most.Stream<any>;

const processRibbonFinished = (
  pageCache: PageCache,
): ProcessRibbonFinished => (
  path,
) => (data) => {
  // update each ribbon in the page cache
  const ribbonData = r.pipe(
    r.converge(
      r.assoc('id'),
      [ r.prop('id'), r.prop('ribbon') ],
    ),
    r.assoc('ready', true),
  //@ts-ignore
  )(data);

  const cached = pageCache.get(path);
  const { ribbons } = cached;

  //ensure ribbons are inserted in order obtained in prefetch
  const index = r.findIndex(r.propEq('id', ribbonData.id), ribbons);
  pageCache.put(
    path,
    r.over(
      r.lensProp('ribbons'),
      r.update(index, ribbonData),
      cached,
    ),
  );

  return r.pipe(
    r.objOf('payload'),
    r.assoc('type', FetchType.RIBBON_FETCHED),
    most.of,
  )(ribbonData);
};

export default processRibbonFinished;
