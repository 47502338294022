import { Stream } from 'most';
import { PageCache } from '../../utils/pageCache';
import * as r from 'ramda';

import { create } from '@most/create';
import { FetchType } from 'domain/constants/ribbons';

export type FetchFromCache = (path: string) => Stream<any>;

const fetchFromCache = (
  pageCache: PageCache,
): FetchFromCache => (path: string) => {
  return create((add) => {
    const cached = pageCache.get(path);

    add({
      type: FetchType.RIBBONS_PREFETCHED,
      payload: cached.ribbons,
    });

    r.forEach(
      r.pipe(
        r.objOf('payload'),
        r.assoc('type', FetchType.RIBBON_FETCHED),
        add,
      )
    )(cached.ribbons);

    add({
      type: FetchType.PAGE_LANGUAGE,
      payload: cached.meta,
    });

    add({
      type: FetchType.RIBBONS_FETCHED,
      payload: {},
    });
  });
};

export default fetchFromCache;
