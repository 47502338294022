import { createEpic } from '@ux/fabric';
import { History } from 'core/navigation';
import { Bus } from '@ux/bus';
import { combineEpics } from 'redux-most';
import {
  PAGE_LANGUAGE_FETCHED,
  PageLanguageFetched,
} from 'domain/messages/ribbons';

const validatePageLanguageEpic = (bus: Bus) => createEpic({
  signal: PAGE_LANGUAGE_FETCHED,
  process: (action: PageLanguageFetched) => {
    bus.dispatch('@@usf/tmp/validate-page-language', action);
  },
});

const validatePagePathEpic = (history: History, window: Window) => createEpic({
  signal: PAGE_LANGUAGE_FETCHED,
  process: (action: PageLanguageFetched, state: any) => {
    const {
      router: {
        location: {
          pathname,
        },
      },
    } = state;
    const {
      payload: {
        path,
        statusCode,
      },
    } = action;

    // if the CMS returns us a path that's slightly different from the requested path
    // we want to update the URL to match. For example if we go to /en but the actual page
    // is /en/ - it's close enough not to 404 but we want the URL to be exact
    // we have to check the CMS's status code as we can get a 404 where the path is just empty
    // but we don't want to replace the URL in that case
    if (statusCode === 301 && path != null && pathname !== path) {
      // we don't want to do a connected-react-router replace as that would
      // trigger a page reload, we just want to update the url and carry on
      history.replaceState({}, '', path);
    }

    // if the CMS returns a 404, we want to hard-redirect to the 404 page
    // we have to be explicit in the use of the trailing slash otherwise
    // when we've redirected, the CMS will once again tell us we're meant to be at /404/
    // this is a standard amongst brands, to have trailing slashes
    if (statusCode === 404 && pathname !== '/404'&& pathname !== '/404/') {
      window.location.pathname = '/404/';
    }
  },
});

export default (history: History, window: Window, bus: Bus) => combineEpics([
  validatePageLanguageEpic(bus),
  validatePagePathEpic(history, window),
]);
