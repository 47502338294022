import {
  AddToBasket as AddToBasketService,
  AddDomainToBasket as AddDomainToBasketService,
} from 'core/basket';
import { Bus } from '@ux/bus';

import { createEpic } from '@ux/fabric';
import { signals as rkSignals } from '@ux/reactive-knowledge';
import { combineEpics } from 'redux-most';
import * as most from 'most';
import * as r from 'ramda';
import * as rA from 'ramda-adjunct';
import { SendFBAnalytics } from '@ux/tagmg';

import {
  ADD_TO_BASKET,
  ADD_DOMAIN_TO_BASKET,
  AddToBasket,
  AddDomainToBasket,
} from 'application/signals/basket';
import {
  ADDED_TO_BASKET,
  ADD_TO_BASKET_FAILED,
  AddedToBasket,
  AddToBasketFailed,
  addingToBasket,
  addedToBasket,
  addToBasketFailed,
  addToBasketReset,
} from 'domain/messages/basket';
import { getBrandId, getCurrency } from 'domain/selectors/common';

export const addToBasketEpic = (
  addToBasket: AddToBasketService,
  dispatchFBEvent: SendFBAnalytics,
) => createEpic({
  signal: [ ADD_TO_BASKET ],
  join: true,
  pending: r.pipe(
    // @ts-ignore
    r.prop('payload'),
    r.pick([ 'buttonId', 'productId' ]),
    r.values,
    r.apply(addingToBasket),
  ),
  process: (action: AddToBasket, state: any) => {
    const productData = action.payload.productData;
    const productId = action.payload.productId;
    const currency = getCurrency(state);
    dispatchFBEvent('AddToCart', {
      //eslint-disable-next-line camelcase
      content_ids: [ productId ],
      //eslint-disable-next-line camelcase
      content_name: productData.name,
      value: productData.price * action.payload.quantity,
      quantity: action.payload.quantity,
      //eslint-disable-next-line camelcase
      content_type: productData.category,
      currency: currency,
    });

    return addToBasket(
      getBrandId(state),
      action.payload.productId,
      action.payload.pricingTier,
      void 0,
      action.payload.quantity,
      action.payload.productData,
    );
  },
  onSuccess: (data, action: AddToBasket) => {
    const resolve = r.identity;
    const fqdn = '';
    const {
      buttonId,
      productId,
      postAction,
    } = action.payload;
    return addedToBasket(
      buttonId,
      productId,
      postAction,
      fqdn,
      false,
      resolve,
    );
  },
  onFailure: (err: Error, action: AddToBasket) => {
    const reject = r.identity;

    return addToBasketFailed(
      err,
      action.payload.buttonId,
      action.payload.productId,
      reject,
    );
  },
});

export const addDomainToBasketEpic = (
  addToBasket: AddDomainToBasketService,
  dispatchFBEvent: SendFBAnalytics,
) => createEpic({
  signal: [ ADD_DOMAIN_TO_BASKET ],
  join: true,
  pending: r.pipe(
    r.prop('payload'),
    r.pick([ 'buttonId', 'productId' ]),
    r.values,
    r.apply(addingToBasket),
  ),
  process: (action: AddDomainToBasket, state: any) => {
    const fqdn = action.payload.fqdn;
    const currency = getCurrency(state);
    dispatchFBEvent('AddToCart', {
      //eslint-disable-next-line camelcase
      content_type: 'domain',
      //eslint-disable-next-line camelcase
      content_name: fqdn.replace(fqdn.split('.')[0], ''),
      currency: currency,
    });
    return addToBasket(
      getBrandId(state),
      action.payload.fqdn,
      action.payload.type,
    );
  },
  onSuccess: (data: any, action: AddDomainToBasket) => {
    const resolve = r.pathOr(r.identity, [ 'meta', 'resolve' ], action);
    const buttonId = '';
    const productId = '';
    const postAction = '';
    const {
      fqdn,
      silent,
    } = action.payload;
    return addedToBasket(
      buttonId,
      productId,
      postAction,
      fqdn,
      silent,
      resolve,
    );
  },
  onFailure: (err: Error, action: AddDomainToBasket) => {
    const reject = r.pathOr(r.identity, [ 'meta', 'reject' ], action);

    return addToBasketFailed(
      err,
      '',
      action.payload.fqdn,
      reject,
    );
  },
});

const addedToBasketEpic = (bus: Bus) => createEpic({
  signal: ADDED_TO_BASKET,
  process: (action: AddedToBasket) => {
    const resolve = r.pathOr(r.identity, [ 'meta', 'resolve' ], action);
    resolve();
  },
  onSuccess: (x, action: AddedToBasket) => {
    bus.dispatch('@@usf/tmp/refresh-basket', {});

    if (action.payload.silent) {
      return [];
    }
    return [
      rkSignals.add({
        type: 'SMIRK',
        message: 'usf.addToBasket.success',
      }),
    ];
  },
});

const addToBasketFailedEpic = () => createEpic({
  signal: ADD_TO_BASKET_FAILED,
  process: (action: AddToBasketFailed) => {
    action.meta.reject();
  },
  onSuccess: r.always(rkSignals.add({
    type: 'ERK',
    message: 'usf.addToBasket.failure',
  })),
});

const resetEpic = () => createEpic({
  signal: [ ADDED_TO_BASKET, ADD_TO_BASKET_FAILED ],
  onSuccess: (action: AddedToBasket | AddToBasketFailed) => most.of(
    addToBasketReset(
      rA.dispatch([
        r.path([ 'payload', 'buttonId' ]),
        r.path([ 'meta', 'buttonId' ]),
      ])(action),
      rA.dispatch([
        r.path([ 'payload', 'productId' ]),
        r.path([ 'meta', 'productId' ]),
      ])(action),
    ),
  ).delay(1000),
});

export default (
  addToBasket: AddToBasketService,
  addDomainToBasket: AddDomainToBasketService,
  dispatchFBEvent: SendFBAnalytics,
  bus: Bus,
) => combineEpics([
  addToBasketEpic(addToBasket, dispatchFBEvent),
  addDomainToBasketEpic(addDomainToBasket, dispatchFBEvent),
  addedToBasketEpic(bus),
  addToBasketFailedEpic(),
  resetEpic(),
]);
