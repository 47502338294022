import { createAction, Action } from 'redux-actions';

const prefix = '@@usf/ribbons/S_';
export const FETCH = `${prefix}FETCH`;
export const SET_VISIBILITY = `${prefix}SET_VISIBILITY`;
export const SEND_IMPRESSION = `${prefix}SEND_IMPRESSION`;

export type Fetch = Action<{
  attempts: number,
  canFetchFromCache: boolean,
}>;
export type SetVisibility = Action<Object>;
export type SendImpression = Action<{
  type: string,
  view: string,
  productId: string,
  promotion: string,
  ribbonType: string,
  ribbonName?: string,
}>;

export const fetch = createAction(FETCH, ({
  attempts = 0,
  canFetchFromCache = true,
} = {}) => ({
  attempts,
  canFetchFromCache,
}));
export const setVisibility = createAction(SET_VISIBILITY);
export const sendImpression = createAction(SEND_IMPRESSION);
