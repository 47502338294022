import {
  Listen,
} from 'core/ribbons/domain-search';

import * as mostc from 'mostc';
import * as mA from 'most-adjunct';
import { select } from 'redux-most';
import * as r from 'ramda';

import {
  INITIALIZE,
  purchase,
  purchaseBundle,
  transfer,
  backorder,
  viewBasket,
  updateQueryParam,
} from 'application/signals/ribbons/domain-search';

// eslint-disable-next-line max-len
const initializeEpic = (
  listen: Listen,
) => r.pipe(
  select(INITIALIZE),
  mA.switchMap(() => {
    const listen$ = listen();

    return r.pipe(
      mostc.map(
        r.cond([
          [
            r.propEq('type', 'PURCHASE'),
            purchase,
          ],
          [
            r.propEq('type', 'PURCHASE_BUNDLE'),
            purchaseBundle,
          ],
          [
            r.propEq('type', 'TRANSFER'),
            transfer,
          ],
          [
            r.propEq('type', 'BACKORDER'),
            backorder,
          ],
          [
            r.propEq('type', 'VIEW_BASKET'),
            viewBasket,
          ],
          [
            r.propEq('type', 'SEARCHING'),
            updateQueryParam,
          ],
          [
            r.T,
            r.always(null),
          ],
        ]),
      ),
    )(listen$);
  }),
  mostc.filter(Boolean),
);

export default initializeEpic;
