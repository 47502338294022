import { PageCache } from '../../utils/pageCache';

import * as r from 'ramda';
import * as most from 'most';
import { FetchType } from 'domain/constants/ribbons';
import { Ribbon } from 'core/ribbons';

const parseMetaData = (pathname: string, data: Object) => r.pipe(
  r.propOr({}, 'meta'),
  // the api doesn't send a status code if everything was okay
  // but we want to have a code for every page request
  // so we default it to success (200)
  r.over(
    r.lensProp('statusCode'),
    r.defaultTo(200),
  ),
  // if we are given a path that does not exactly match the current path
  // we will flag it as a redirect
  r.unless(
    r.anyPass([
      // if the returned path matches the requested path, everything is okay
      r.propEq('path', pathname),
      // if path is null, there is probably something more serious than just a redirect going on
      r.propSatisfies(r.isNil, 'path'),
      // if status code is a specific error (like 404) we don't want to overwrite it
      r.propSatisfies(r.complement(r.equals(200)), 'statusCode'),
    ]),
    r.assoc('statusCode', 301),
  ),
)(data);
const parsePageRibbons: any = r.pipe(
  r.propOr([], 'page'),
  //@ts-ignore
  r.map(r.assoc('ready', false)),
);

// eslint-disable-next-line max-len
export type ProcessPageFetched = (path: string, processRibbonFinished: Function) => (data: Object) => most.Stream<any>;
// eslint-disable-next-line max-len
const processPageFetched = (pageCache: PageCache): ProcessPageFetched => (path, processRibbonFinished) => (data: any) => {
  let result$ = most.empty();
  const partial = r.prop('partial', data);
  const pageRibbons = parsePageRibbons(data);
  const metaData = parseMetaData(window.location.pathname, data);

  // setup page cache for this page, this puts ribbons in correct order
  pageCache.put(path, {
    ribbons: pageRibbons,
    meta: metaData,
  });

  r.pipe(
    r.objOf('payload'),
    r.assoc('type', FetchType.RIBBONS_PREFETCHED),
    (x) => {
      result$ = most.merge(result$, most.of(x));
    },
  )(pageRibbons);

  // emit the meta object
  r.pipe(
    r.objOf('payload'),
    r.assoc('type', FetchType.PAGE_LANGUAGE),
    (x) => {
      result$ = most.merge(result$, most.of(x));
    },
  )(metaData);

  if (r.equals(partial, false)) {
    // when partial is false we will not get any further ribbon updates so each
    // one needs to be called as finished here
    r.forEach(
      r.pipe(
        (ribbon: Ribbon) => r.assoc('ribbon', ribbon)(ribbon),
        // JSON.stringify,
        processRibbonFinished(path),
        (stream$: most.Stream<any>) => {
          result$ = most.merge(result$, stream$);
        },
      )
      // @ts-ignore
    )(pageRibbons);
  }

  return result$;
};

export default processPageFetched;
