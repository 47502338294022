import React from 'react';
import RibbonRaid from 'components/RibbonRaid';
import { WubLoader } from '@ux/penders';
import LoadFailed from './LoadFailed';
import pureEnhance from '@ux/pure-enhance';
import { Theme } from '@ux/theme-utils';
import * as r from 'ramda';

export interface OuterProps {
  pageLoadFailed: boolean,
  initialized: boolean,
  theme: Theme,
  onFetch: (evt: any) => void,
}

interface InnerProps extends OuterProps {}

const PureCMPage = r.cond([
  [
    r.propEq('pageLoadFailed', true),
    ({ theme, onFetch }: InnerProps) => (
      <LoadFailed
        theme={theme}
        onRetry={onFetch}
      />
    ),
  ],
  [
    r.propEq('initialized', false),
    ({ theme }: InnerProps) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          minHeight: 400,
          flex: 1,
          flexBasis: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <WubLoader
          theme={theme}
          color={theme.palette.highlight}
          size={60}
        />
      </div>
    ),
  ],
  [
    r.T,
    () => (
      <div>
        <RibbonRaid/>
      </div>
    ),
  ],
]);

export const enhance = pureEnhance<InnerProps, OuterProps>('CMPage');

export default enhance(PureCMPage);
