import { createAction, ActionMeta } from 'redux-actions';
import { payloadCreator } from '@ux/fabric';
import * as r from 'ramda';

const prefix = '@@usf/callback/S_';
export const DESIRE_CALLBACK = `${prefix}DESIRE_CALLBACK`;
export const SUBMIT_DETAILS = `${prefix}SUBMIT_DETAILS`;
export const VALIDATE = `${prefix}VALIDATE`;

type Values = {
  name: string,
  company: string,
  email: string,
  phone: string,
  date: Date,
  message: string,
  captcha: string,
};

export type DesireCallback = ActionMeta<null, {
  form: string,
}>;
export type SubmitDetails = ActionMeta<{
  values: Values,
}, {
  form: string,
}>;
export type Validate = ActionMeta<{
  values: Values,
}, {
  form: string,
  resolve: Function,
  reject: Function,
}>;

export const desireCallback = createAction(
  DESIRE_CALLBACK,
  r.always(null),
  payloadCreator([ 'form' ]),
);

export const submitDetails = createAction(
  SUBMIT_DETAILS,
  payloadCreator([ 'values' ]),
  payloadCreator([ 'form' ], 1),
);
export const validate = createAction(
  VALIDATE,
  payloadCreator([ 'values' ]),
  payloadCreator([ 'form', 'resolve', 'reject' ], 1),
);
