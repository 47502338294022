import { GetProductData } from 'core/cta';
import { Middleware } from '@ux/drivers';

const getProductData = (
  middleware: Middleware,
): GetProductData => ({
  productId,
  skuId,
  locale = 'en',
}) => {
  return middleware(0, 'getProduct', {
    productId,
    skuId,
    locale,
  });
};

export default getProductData;
