import { Colorway } from 'domain/constants/colorway';
import color from 'color';
import * as r from 'ramda';
import { Theme } from '@ux/theme-utils';
import { Against } from '@ux/reactive-button';
import { UnsafeColorway } from 'core/ribbons/primitives';

// Colorway general rules
// https://sketch.cloud/s/ZWdJY/p/c-colourway-general-rules

export const getTitleColor = (theme: Theme, against: Colorway|UnsafeColorway) => r.cond([
  [ r.equals(Colorway.LIGHT), r.always(theme.palette.grey02) ],
  [ r.equals(Colorway.NEUTRAL), r.always(theme.palette.grey02) ],
  [ r.equals(Colorway.DARK), r.always('#fff') ],
  [ r.equals(Colorway.PRIMARY), r.always(theme.palette.primaryContrast) ],
  [
    r.equals(Colorway.PRIMARY_GRADIENT),
    r.always(theme.palette.primaryContrast),
  ],
  [ r.equals(Colorway.HIGHLIGHT), r.always(theme.palette.highlightContrast) ],
  [ r.T, r.always(theme.palette.grey02) ],
])(against);

export const getBodyColor = (theme: Theme, against: Colorway|UnsafeColorway) => r.cond([
  [ r.equals(Colorway.LIGHT), r.always(theme.palette.grey04) ],
  [ r.equals(Colorway.NEUTRAL), r.always(theme.palette.grey04) ],
  [ r.equals(Colorway.DARK), r.always(theme.palette.grey11) ],
  [ r.equals(Colorway.PRIMARY), r.always(
    color(theme.palette.primaryContrast)
      .alpha(0.85)
      .string()
  ) ],
  [ r.equals(Colorway.PRIMARY_GRADIENT), r.always(
    color(theme.palette.primaryContrast)
      .alpha(0.85)
      .string()
  ) ],
  [ r.equals(Colorway.HIGHLIGHT), r.always(
    color(theme.palette.highlightContrast)
      .alpha(0.85)
      .string()
  ) ],
  [ r.T, r.always(theme.palette.grey04) ],
])(against);

export const getBackgroundColor = (theme: Theme, colorway: Colorway) => r.cond([
  [ r.equals(Colorway.LIGHT), r.always('#fff') ],
  [ r.equals(Colorway.NEUTRAL), r.always(theme.palette.grey12) ],
  [ r.equals(Colorway.DARK), r.always(theme.palette.grey02) ],
  [ r.equals(Colorway.PRIMARY), r.always(theme.palette.primary) ],
  [ r.equals(Colorway.PRIMARY_GRADIENT), r.always(theme.palette.primary) ],
  [ r.equals(Colorway.HIGHLIGHT), r.always(theme.palette.highlight) ],
  [ r.T, r.always('#fff') ],
])(colorway);

export const getBackgroundAccent = (theme: Theme, colorway: Colorway) => r.ifElse(
  r.equals(Colorway.LIGHT),
  r.always(theme.palette.grey12),
  r.always('#fff'),
)(colorway);

export const getAgainst = r.cond([
  [ r.equals(Colorway.LIGHT), r.always(Against.LIGHT) ],
  [ r.equals(Colorway.NEUTRAL), r.always(Against.NEUTRAL) ],
  [ r.equals(Colorway.DARK), r.always(Against.DARK) ],
  [ r.equals(Colorway.PRIMARY), r.always(Against.PRIMARY) ],
  [ r.equals(Colorway.PRIMARY_GRADIENT), r.always(Against.PRIMARY) ],
  [ r.equals(Colorway.HIGHLIGHT), r.always(Against.HIGHLIGHT) ],
  [ r.T, r.always(Against.LIGHT) ],
]);

export const getContrastColor = (theme: Theme, colorway: Colorway) => r.cond([
  [ r.equals(Colorway.LIGHT), r.always(theme.palette.primary) ],
  [ r.equals(Colorway.NEUTRAL), r.always(theme.palette.primary) ],
  [ r.equals(Colorway.DARK), r.always('#fff') ],
  [ r.equals(Colorway.PRIMARY), r.always(theme.palette.primaryContrast) ],
  [ r.equals(Colorway.PRIMARY_GRADIENT),
    r.always(theme.palette.primaryContrast) ],
  [ r.equals(Colorway.HIGHLIGHT), r.always(theme.palette.highlightContrast) ],
  [ r.T, r.always(theme.palette.primary) ],
])(colorway);
