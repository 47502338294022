import { Core } from 'core';

import { combineEpics } from 'redux-most';

import rootEpics from './root';
import ribbons from './ribbons';
import basket from './basket';
import configurator from './configurator';
import cta from './cta';

export default (deps: Core) => combineEpics([
  rootEpics(deps),
  ribbons(deps),
  basket(deps),
  configurator(deps),
  cta(deps),
]);
