import {
  createValidationEpic,
  structErrorToReduxFormError,
  validateStructSchema,
} from '@ux/form-utils';
import * as r from 'ramda';

import schema from 'domain/logic/schemas/callback';
import {
  VALIDATE,
} from 'application/signals/ribbons/callback';
import {
  validateSuccess,
  validateFailure,
} from 'domain/messages/ribbons/callback';

const validateEpic = createValidationEpic({
  form: r.path([ 'meta', 'form' ]),
  signal: VALIDATE,
  success: validateSuccess,
  failure: validateFailure,
  schema: r.always(schema),
  validate: validateStructSchema,
  mapError: structErrorToReduxFormError('usf.ribbons.callback.errors'),
});

export default validateEpic;
