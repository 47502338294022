import { createCommands } from '@ux/view-aggregate';

import { ADD_TO_BASKET } from 'application/signals/basket';
import {
  ADDING_TO_BASKET,
  ADDED_TO_BASKET,
  ADD_TO_BASKET_FAILED,
  ADD_TO_BASKET_RESET,
  APPLYING_VOUCHER,
  VOUCHER_APPLIED,
  VOUCHER_FAILED,
  VOUCHER_RESET,
  AddingToBasket,
  AddedToBasket,
  AddToBasketFailed,
  AddToBasketReset,
  ApplyingVoucher,
  VoucherApplied,
  VoucherFailed,
  VoucherReset,
} from 'domain/messages/basket';

//It apparently doesn't like the structure
//it still works fine, false positive.
//@ts-ignore
export default createCommands({
  [ADDING_TO_BASKET]: (action: AddingToBasket) => ({
    id: action.payload.buttonId,
    type: ADD_TO_BASKET,
    status: 'REQUESTED',
  }),
  [APPLYING_VOUCHER]: (action: ApplyingVoucher) => ({
    id: action.payload.buttonId,
    type: ADD_TO_BASKET,
    status: 'REQUESTED',
  }),
  [ADDED_TO_BASKET]: (action: AddedToBasket) => ({
    id: action.payload.buttonId,
    type: ADD_TO_BASKET,
    status: 'SUCCESS',
  }),
  [VOUCHER_APPLIED]: (action: VoucherApplied) => ({
    id: action.payload.buttonId,
    type: ADD_TO_BASKET,
    status: 'SUCCESS',
  }),
  [ADD_TO_BASKET_FAILED]: (action: AddToBasketFailed) => ({
    id: action.meta.buttonId,
    type: ADD_TO_BASKET,
    status: 'FAILED',
  }),
  [VOUCHER_FAILED]: (action: VoucherFailed) => ({
    id: action.meta.buttonId,
    type: ADD_TO_BASKET,
    status: 'FAILED',
  }),
  [ADD_TO_BASKET_RESET]: (action: AddToBasketReset) => ({
    id: action.payload.buttonId,
    type: ADD_TO_BASKET,
    status: void 0,
  }),
  [VOUCHER_RESET]: (action: VoucherReset) => ({
    id: action.payload.buttonId,
    type: ADD_TO_BASKET,
    status: void 0,
  }),
});
