import { combineReducers } from 'redux';
import {
  connectRouter,
} from 'connected-react-router';
import ux from './ux';
import commands from './commands';
import usf, {
  State as Usf,
} from './usf';

export type State = {
  usf: Usf,
};

export default (history: any) => combineReducers({
  ...ux,
  router: connectRouter(history),
  commands,
  usf,
});
