import { ExperienceCookie } from 'core/ribbons/experiences';

//check if 2 arrays intersect
export const intersects = (arrayA: any[], arrayB: any[]) => {
  return arrayA.some((x) => arrayB.includes(x));
};

//priority is NO CONFIG->EXCLUSION->INCLUSION
export const showBasedOnExperiences = (
  ribbonExperiences: {
    in?: string[],
    not?: string[],
  },
  cookieExperiences = [] as string[],
) => {
  const ribbonIn = ribbonExperiences?.in ?? [];
  const ribbonNot = ribbonExperiences?.not ?? [];
  const hasCookieConfig = cookieExperiences.length > 0;

  //treat the empty ribbon experiences case
  if (ribbonIn.length === 0 && ribbonNot.length === 0) {
    return true;
  }

  if (!hasCookieConfig) {
    if (ribbonNot.length) {
      return true;
    }
    //this ribbon is included in an experience, we don't have a config
    //so that experience is absent
    return ribbonIn.length === 0;
  }

  // From here on out we know we have a config:
  // Treat the NOT case.
  if (ribbonNot.length) {
    //if there's no intersection, we show
    const ribbonConfigIntersects = intersects(ribbonNot, cookieExperiences);

    //not takes priority
    //if not does not intersect, we need to check IN too,
    //we can't simply return
    if (ribbonConfigIntersects) {
      return false;
    }
  }

  // Treat the IN case.
  // We didn't go through the "NOT case".
  // We only work with IN config and cookie config
  if (ribbonIn.length) {
    return intersects(ribbonIn, cookieExperiences);
  }

  //In previous cases with config && not we only know we either
  //1: no not ribbon config
  //2: not ribbon config but no intersecttion
  //In previous case with IN we know we have IN config
  //If we dont have a ribbonIn, we show by default
  return true;
};

export const getExperiencesConfig = (experimentationCookie?: string) => {
  let experienceConfig: ExperienceCookie;
  if (!experimentationCookie) {
    return {};
  }
  try {
    const decodedExperimentationCookie = decodeURIComponent(experimentationCookie);
    experienceConfig = JSON.parse(decodedExperimentationCookie) ?? {};
  } catch (e) {
    experienceConfig = {};
  }
  return experienceConfig;
};

export default null;