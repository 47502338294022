import { Core } from 'core';

import {
  dispatchFBEvent,
  dispatchGAEvent,
  dispatchEcommEvent,
  dispatch,
  ecommerce,
} from '@ux/tagmg';
import { notifyCradle } from '@ux/cradle';
import { createDelayedQueue } from '@ux/queue';
import { bus } from '@ux/bus';
import drivers from './drivers';
import _navigation from './navigation';
import _ribbons from './ribbons';
import _basket from './basket';
import _configurator from './configurator';
import _root from './root';
import _cta from './cta';
import pageCacheFactory from './utils/pageCache';
import _cache from './cache';
import { get as getCookie } from 'browser-cookies';

export default (): Core => {
  const {
    ndjson,
    middleware,
  } = drivers();

  const navigation = _navigation(window);

  const pageCache = pageCacheFactory();

  return {
    ribbons: _ribbons(
      ndjson,
      navigation.getPath,
      navigation.getQuery,
      document,
      middleware,
      pageCache,
      dispatchGAEvent,
    ),
    basket: _basket(middleware, createDelayedQueue),
    configurator: _configurator(middleware),
    root: _root(document),
    navigation,
    dispatch,
    dispatchGAEvent,
    dispatchFBEvent,
    dispatchEcommEvent,
    notifyCradle,
    getCookie,
    ecommerce,
    bus,
    cta: _cta(middleware),
    cache: _cache(pageCache),
  };
};
