import { Listen } from 'core/ribbons/domain-search';

import { create } from '@most/create';
import * as r from 'ramda';

interface evtProps extends Event {
  detail?: {
    fqdn?: string,
    fqdns?: string[],
    resolve?: any,
    reject?: any,
    query?: string,
  }
}
const listen = (
  document: Document,
): Listen => r.always(
  create((add) => {
    document.addEventListener('@dst/domain_purchase_requested', (evt: evtProps) => {
      add({
        type: 'PURCHASE',
        fqdn: evt.detail.fqdn,
        resolve: evt.detail.resolve,
        reject: evt.detail.reject,
      });
    });

    document.addEventListener('@dst/domain_bundle_purchase', (evt: evtProps) => {
      add({
        type: 'PURCHASE_BUNDLE',
        //@ts-ignore
        fqdns: evt.detail.fqdns,
        resolve: evt.detail.resolve,
        reject: evt.detail.reject,
      });
    });

    document.addEventListener('@dst/transfer_domain', (evt: evtProps) => {
      //@ts-ignore
      add({
        type: 'TRANSFER',
        fqdn: evt.detail.fqdn,
        resolve: evt.detail.resolve,
        reject: evt.detail.reject,
      });
    });

    document.addEventListener('@dst/backorder_domain', (evt: evtProps) => {
      //@ts-ignore
      add({
        type: 'BACKORDER',
        fqdn: evt.detail.fqdn,
        resolve: evt.detail.resolve,
        reject: evt.detail.reject,
      });
    });

    document.addEventListener('@dst/view_basket', () => {
      //@ts-ignore
      add({
        type: 'VIEW_BASKET',
      });
    });
    document.addEventListener('@dst/searching', (evt: evtProps) => {
      //@ts-ignore
      add({
        type: 'SEARCHING',
        //@ts-ignore
        query: evt.detail.query,
      });
    });
  }),
);

export default listen;
