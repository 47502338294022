import { Core } from 'core';

import { combineEpics } from 'redux-most';

import fetch from './fetch';
import location from './location';
import language from './language';
import setVisibility from './setVisibility';
import impression from './impression';
import callback from './callback';
import domainSearch from './domain-search';
import domainSearchStart from './domain-search-start';
import websiteReportStart from './website-report-start';
import { bus } from '@ux/bus';

export default (deps: Core) => combineEpics([
  fetch(deps.ribbons.fetch),
  location(deps.dispatchGAEvent, deps.dispatch, deps.getCookie),
  language(deps.navigation.history, window, bus),
  impression(deps.cta.getProductData, deps.ecommerce),
  setVisibility(),
  callback(deps),
  domainSearch(deps),
  domainSearchStart(deps),
  websiteReportStart(),
]);
