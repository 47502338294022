import { useSaveLanguage, useLanguage } from '@ux/language';
import { useSubscribe } from '@ux/bus';
import { useRefreshBasketCount } from '@ux/bookends';
import { PageLanguageFetched } from 'domain/messages/ribbons';

// @TODO: remove these hacks as soon as possible
const useRefreshBasketHack = () => {
  const refreshBasketCount = useRefreshBasketCount();
  useSubscribe('@@usf/tmp/refresh-basket', () => {
    refreshBasketCount();
  });
};
const useValidatePageLanguageHack = () => {
  const [ saveLanguage ] = useSaveLanguage();
  const { data: preferred } = useLanguage();
  useSubscribe('@@usf/tmp/validate-page-language', async(action: PageLanguageFetched) => {
    const current = action.payload.language;

    if (current == null) {
      return [];
    }

    if (preferred === current) {
      return [];
    }
    await saveLanguage({
      language: current,
    });
  }, [ preferred, saveLanguage ]);
};

export default () => {
  useRefreshBasketHack();
  useValidatePageLanguageHack();
};
