import { selectors as vaSelectors } from '@ux/view-aggregate';
import { selectors as wlSelectors } from '@ux/whitelabel';
import * as r from 'ramda';

import { ADD_TO_BASKET } from 'application/signals/basket';

const { getCommandStatus } = vaSelectors;

export const getAddingStatus = r.curryN(2, r.ifElse(
  r.isNil,
  r.always(void 0),
  (productId: string, state: any) => getCommandStatus(
    ADD_TO_BASKET,
    productId,
    state,
  ),
));

export const getBasketUrl = wlSelectors.getUrl('basket');

export const getOrderProcessUrl = wlSelectors.getUrl('orderProcess.index');
