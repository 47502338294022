import { connect } from '@ux/fabric';
import { withProps, withHandlers, tap } from '@team-griffin/rehook';
import pureEnhance from '@ux/pure-enhance';
import { createStructuredSelector } from 'reselect';
import * as r from 'ramda';

import { fetch } from 'application/signals/ribbons';
import useCurrencyListener from 'application/actions/ribbons/useCurrencyListener';
import { isInitialized, hasPageLoadFailed } from 'domain/selectors/ribbons';
import CMPage, { OuterProps as PureProps } from './CMPage';

interface ConnectedProps {
  initialized: boolean,
  pathname: string,
  pageLoadFailed: boolean,
  onFetch: (evt: any) => void,
}
interface InnerProps extends PureProps {}
interface OuterProps extends Omit<InnerProps, keyof ConnectedProps> {}


const mapStateToProps = createStructuredSelector({
  initialized: isInitialized,
  pathname: r.path([ 'router', 'location', 'pathname' ]),
  pageLoadFailed: hasPageLoadFailed,
});

const mapDispatchToProps = { fetch };

export const enhance = pureEnhance<InnerProps, OuterProps>('ConnectedCMPage', [
  tap(() => {
    useCurrencyListener();
  }),
  withProps(connect(mapStateToProps, mapDispatchToProps)),
  withHandlers({
    onFetch: ({
      actions: {
        fetch,
      },
    }: {
      actions: {
        fetch: (evt: any) => void,
      }
    }) => fetch,
  }),
]);

export default enhance(CMPage);
