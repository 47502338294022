import { SendAnalytics } from '@ux/tagmg';
import { Reporting } from 'core/ribbons/experiences';

export const setOptimizeGA = (
  dispatch: SendAnalytics,
  dispatchGAEvent: SendAnalytics,
  reportingObject?: Reporting,
) => {
  if (!reportingObject) {
    return;
  }
  try {
    const id = reportingObject.googleOptimizeExperimentId;
    const variant = reportingObject.var;
    if (id && typeof variant === 'number') {
      dispatch('set', 'exp', `${id}.${variant}`);
      dispatchGAEvent(
        'optimize',
      );
    }
  // eslint-disable-next-line no-empty
  } catch (e) {}
};