import { combineEpics } from 'redux-most';
import { createEpic } from '@ux/fabric';
import { getFormValues } from 'redux-form';
import * as r from 'ramda';
import {
  SUBMIT,
  Submit,
} from 'application/signals/ribbons/domain-search-start';
import {
  SUBMIT as WR_SUBMIT,
  Submit as WrSubmit,
} from 'application/signals/ribbons/website-report-start';
import { getPushAction } from 'application/utils';
import { createRibbonName } from 'domain/transformers/ribbons';
import { SendAnalytics } from '@ux/tagmg';

// takes the user input and the provided tld
// if the user input doesn't have its own tld, we append the provided one
const createFqdn = r.ifElse(
  r.both(
    r.complement(r.test(/\./)),
    r.pipe(
      r.nthArg(1),
      Boolean,
    ),
  ),
  r.pipe(
    r.unapply(r.identity),
    r.join(''),
  ),
  r.pipe(
    r.identity,
    r.defaultTo(''),
  )
);

// tld could either be com, .com, or null
// if the . is missing we want to prefix it
// if it's null we still want a string to be returned
const prefxTld = r.ifElse(
  r.isNil,
  r.always(''),
  r.unless(
    r.startsWith('.'),
    r.concat('.'),
  ),
);

const submitEpic = () => createEpic({
  signal: SUBMIT,
  onSuccess: (x, action: Submit, state: any) => {
    //const domain: any Property 'domain' does not exist on type '{} | {}'.ts(2339)
    //it's actually a false positive on this error
    //@ts-ignore
    const { domain } = r.defaultTo({}, getFormValues(action.payload.form)(state));
    const tld = prefxTld(action.payload.tld);
    //Expected 1 arguments, but got 2.ts(2554)
    //false positive
    //@ts-ignore
    const fqdn = createFqdn(domain, tld);
    const target = `${action.payload.destination}?domain=${encodeURIComponent(fqdn)}`;
    return getPushAction(target);
  },
});

const trackSubmitEpic = (dispatchGAEvent: SendAnalytics) => createEpic({
  signal: [ SUBMIT, WR_SUBMIT ],
  process: (action: Submit | WrSubmit) => {
    const name = createRibbonName(
      r.path([ 'payload', 'ribbonType' ], action),
      r.path([ 'payload', 'ribbonName' ], action),
    );

    dispatchGAEvent({
      hitType: 'event',
      eventCategory: 'USF Interaction',
      eventAction: 'form submit',
      eventLabel: name,
    });
  },
});

export default (dispatchGAEvent: SendAnalytics) => combineEpics([
  submitEpic(),
  trackSubmitEpic(dispatchGAEvent),
]);
