import {
  IsPageLoaderDestroyed,
  NotifyScout,
} from 'core/root';
import * as mA from 'most-adjunct';
import { select, combineEpics } from 'redux-most';
import * as r from 'ramda';
import {
  SCOUT,
  scout,
} from 'application/signals/root';
import {
  getPagePath,
  getPageStatus,
} from 'domain/selectors/ribbons';
import { Store } from 'redux';
import { Stream } from 'most';

const initScoutEpic = (
  isPageLoaderDestroyed: IsPageLoaderDestroyed
) => (actions$: Stream<any>, store: Store) => {
  return mA.waitUntil(() => {
    const state = store.getState();
    if (!state.usf.ribbons.fetched) {
      return false;
    }
    if (!isPageLoaderDestroyed()) {
      return false;
    }
    return true;
  }, 500).map(r.always(scout()));
};

const scoutEpic = (
  notifyScout: NotifyScout,
) => (actions$: Stream<any>, store: Store) => r.pipe(
  select(SCOUT),
  mA.switchMap(() => {
    const state = store.getState();
    const status = getPageStatus(state);
    const path = getPagePath(state) as string;
    return mA.fromEagerPromise(() => notifyScout(status, path));
  }),
  mA.ignoreElements,
)(actions$);

export default (
  isPageLoaderDestroyed: IsPageLoaderDestroyed,
  notifyScout: NotifyScout,
) => combineEpics([
  initScoutEpic(isPageLoaderDestroyed),
  //@ts-ignore
  scoutEpic(notifyScout),
]);
