import './polyfill';
import { dispatchGAEvent } from '@ux/tagmg';
import createComposition from './composition';
import App from 'presentation/components/ConnectedApp';
import render from './render';

dispatchGAEvent(
  'timing',
  //@ts-ignore
  'USF Critical Path',
  'start -> main bundle',
  Math.round(performance.now()),
);

const rootElement = document.getElementById('root');

// If the root element already has children
// for example if it has been pre-rendered by Scout.
// Then we shouldn't try and render over the top.
// Instead we should just allow the html to be as is.
if (rootElement.children.length === 0) {
  document.body.addEventListener('click', function(e) {
    try {
      let element = e.srcElement as Element;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 3; i++) {
        const id = element.getAttribute('id');

        if (id == null) {
          element = element.parentElement;
          continue;
        }

        dispatchGAEvent({
          hitType: 'event',
          eventCategory: 'Link Attribution',
          eventAction: 'click',
          eventLabel: id,
        });
        break;
      }
    } catch (ex) {
      // Silently fail just in case some browser doesn't like this.
    }
  });

  const {
    store,
    history,
    init,
  } = createComposition();

  render(App)({
    store,
    history,
  });

  init();
}
