import * as r from 'ramda';
import { ensureArray } from 'ramda-adjunct';
import { BreakpointProps } from '@ux/responsive';

const findIndexed = r.addIndex(r.find);

const mergeArrays = r.curryN(2, (x, y) => {
  const longestIdx = r.max(x.length, y.length);
  //@ts-ignore
  return r.reduce((acc: any, idx: number) => {
    const value = x[idx] === void 0 ? y[idx] : x[idx];
    return r.append(
      value,
      acc
    );
  })([])(r.range(0, longestIdx));
});

// We want to find the item that corresponds
// to the current active breakpoint size.
// However, we can't just do a direct lookup
// as that entry might be `undefined` in which
// case we'd want to waterfall until we find it.
export default function findResponsiveItem(sizes: BreakpointProps, items: any, options: any = {}) {
  const safeItems = r.pipe(
    ensureArray,
    mergeArrays([
      void 0,
      void 0,
      void 0,
    ]),
    r.reverse,
  )(items) as unknown as [any, any, any];

  return findIndexed((item, idx) => {
    const value = r.cond([
      [ r.allPass([
        r.equals(0),
        () => sizes.isMinLg === true,
        () => item !== void 0,
      ]), r.always(safeItems[0]) ],
      [ r.allPass([
        r.equals(1),
        () => sizes.isMinSm === true,
        () => item !== void 0,
      ]), r.always(safeItems[1]) ],
      [ r.allPass([
        r.equals(2),
        () => item !== void 0,
      ]), r.always(safeItems[2]) ],
    ])(idx);

    return r.ifElse(
      r.always(options.supportNull),
      r.complement(r.equals(void 0)),
      r.complement(r.isNil),
    )(value);
    // return (options.supportNull) ? (value) value != null;
  }, safeItems) as any;
}
