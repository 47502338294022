import { createAction, Action, ActionMeta } from 'redux-actions';
import { payloadCreator } from '@ux/fabric';
import * as r from 'ramda';

const prefix = '@@usf/callback/M_';
export const CALLBACK_SUCCEEDED = `${prefix}CALLBACK_SUCCEEDED`;
export const CALLBACK_FAILED = `${prefix}CALLBACK_FAILED`;
export const VALIDATE_SUCCESS = `${prefix}VALIDATE__SUCCESS`;
export const VALIDATE_FAILURE = `${prefix}VALIDATE_FAILURE`;

export type CallbackSucceeded = ActionMeta<{
    data: any, // TODO: determine payload
  }, {
  form: string,
}>;
export type CallbackFailed = ActionMeta<Error, {
  form: string,
}>;
export type ValidateSuccess = Action<{}>;
export type ValidateFailure = Action<Error>;

export const callbackSucceeded = createAction(
  CALLBACK_SUCCEEDED,
  payloadCreator([ 'data' ]),
  payloadCreator([ 'form' ], 1),
);
export const callbackFailed = createAction(
  CALLBACK_FAILED,
  r.identity,
  payloadCreator([ 'form' ], 1),
);
export const validateSuccess: () => ValidateSuccess = createAction(
  VALIDATE_SUCCESS,
);
export const validateFailure: (err: Error) => ValidateFailure = createAction(
  VALIDATE_FAILURE,
);
