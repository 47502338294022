import { Core } from 'core';

import { notifyCradle } from '@ux/cradle';
import { combineEpics } from 'redux-most';
import locationEpic from './location';
import initializeEpic from './initialize';
import scoutEpic from './scout';
import externalPushEpic from './externalPush';
import cradleEpic from './cradle';

export default (deps: Core) => combineEpics([
  locationEpic(deps.dispatchFBEvent),
  // @ts-ignore
  initializeEpic(),
  // @ts-ignore
  cradleEpic(notifyCradle),
  scoutEpic(
    deps.root.isPageLoaderDestroyed,
    deps.root.notifyScout,
  ),
  externalPushEpic(deps.navigation.navigate),
]);
