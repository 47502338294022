import { struct } from 'superstruct';
import {
  required,
  email,
} from '@ux/form-utils';


export default struct.interface({
  name: required(),
  email: struct.intersection([ required(), email('invalid') ]),
  phone: required(),
  captcha: required(),
});
