import { reducer as form } from 'redux-form';
import {
  reducer as pageLoader,
  REDUCER_MOUNT_POINT as PAGE_LOADER_MP,
} from '@team-griffin/redux-page-loader';
import {
  reducer as modal,
  REDUCER_MOUNT_POINT as MODAL_MP,
} from '@team-griffin/redux-modal-router';

import { reducer as reactiveKnowledge } from '@ux/reactive-knowledge';
import {
  reducer as whitelabel,
  REDUCER_MOUNT_POINT as WL_MP,
} from '@ux/whitelabel';
import {
  REDUCER_MOUNT_POINT as DST_MP,
  reducer as dst,
} from '@pex/dst';
import {
  REDUCER_MOUNT_POINT as UPM_MP,
  reducer as upm,
} from '@pex/upm';

export default {
  form,
  [MODAL_MP]: modal,
  reactiveKnowledge,
  [PAGE_LOADER_MP]: pageLoader,
  [WL_MP]: whitelabel,
  [DST_MP]: dst,
  [UPM_MP]: upm,
};
