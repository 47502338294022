import {
  Group,
  Groups,
  Option,
  Options,
  VariantLink,
  VariantLinks,
  Variation,
  Variations,
} from 'core/configurator';

import {
  getFormValues,
} from 'redux-form';
import { createSelector } from 'reselect';
import * as r from 'ramda';
import { mapIndexed } from 'ramda-adjunct';
import {
  filterObjIndexed,
  rejectObjIndexed,
  sortObjBy,
} from '@team-griffin/capra';
import {
  getFirstKey,
  getFirstValue,
  mapKeys,
} from 'crosscutting/ramda';
import qs from 'qs';
import { selectors as wlSelectors } from '@ux/whitelabel';
import { State } from 'domain/reducers/usf/configurator';

const ofIndex = r.flip(r.indexOf);

const VARIATIONS = '@@VARIATIONS';

const getState = r.path([ 'usf', 'configurator' ]);
const getData = createSelector<State, any, Object>(
  getState,
  r.prop('data'),
);

const getIncludeTax = createSelector(
  wlSelectors.getBrandConfig,
  r.path([ 'payment', 'addTaxToDisplayPrice' ]),
);

const getAllGroups = createSelector(
  getData,
  r.pipe(
    r.prop('groups'),
    r.values,
  ),
);

// we will use this to ensure both the user's values
// and the product tree are built in the same order
const getGroupOrder = createSelector(
  getAllGroups,
  r.map(r.prop('id')),
);

// because of PPE's silly naming conventions (spaces and .s in keys!)
// we have to store them under encoded keys
// so when we get the values, we have to decode each of the keys first
const getAllValues = createSelector(
  getFormValues('usf.configurator'),
  // Need to exclude quantity as it doesn't follow the same convention as above
  r.pipe(
    r.dissoc('quantity'),
    mapKeys(atob),
  ),
);

export const isLoading = createSelector(
  getState,
  r.anyPass([
    r.prop('fetching'),
    r.propSatisfies(r.isNil, 'firstFetched'),
  ]),
);

export const hasLoadFailed = createSelector(
  getState,
  r.propSatisfies(r.complement(r.isNil), 'error'),
);

const getQuery = createSelector(
  r.path([ 'router', 'location', 'search' ]),
  r.invoker(2, 'parse')(r.__, { ignoreQueryPrefix: true }, qs),
);

// get the product id from the url (?product=12575)
export const getProductId = createSelector(
  getQuery,
  r.pipe(
    r.prop('productId'),
    Number,
  ),
);

// get the optional pricingTier from the url (&pricingTier=0)
export const getPricingTier = createSelector(
  getQuery,
  r.pipe(
    r.prop('pricingTier'),
    r.defaultTo(null)
  ),
);

const getInitialSkuId = createSelector(
  getQuery,
  r.pipe(
    r.prop('initialSku'),
    Number,
  ),
);

export const getProductName = createSelector(
  getData,
  r.path([ 'product', 'name' ]),
);

export const getProductSpecs = createSelector(
  getData,
  r.pipe(
    r.prop('specs'),
    //@ts-ignore
    r.values,
    // should not be showing a resource if it has 0 quantity
    r.filter(
      r.pipe(
        r.prop('quantity'),
        r.lt(0),
      ),
    ),
  ),
);

export const getBasePrice = createSelector(
  getData,
  getIncludeTax,
  r.converge(
    r.prop,
    [
      r.pipe(
        r.nthArg(1),
        r.ifElse(
          r.equals(true),
          r.always('gross'),
          r.always('net'),
        ),
      ),
      r.path([ 'product', 'price' ]),
    ],
  )
);

const getAllOptions = createSelector(
  getData,
  r.prop('options'),
) as (data: any)=> Options;

const getVariantLinks = createSelector(
  getData,
  r.prop('variantLinks'),
);

const getAllVariations = createSelector(
  getData,
  r.prop('variations'),
);

const getVariations = createSelector(
  getAllVariations,
  getVariantLinks,
  getAllGroups,
  getGroupOrder,
  (variations: Variations, links: VariantLinks, groups: Group[], order: string[]) => {
    const buildVariantions = r.pipe(
      r.values,
      r.map(
        // re-insert the options back in via their ids
        r.over(
          r.lensProp('options'),
          r.pipe(
            r.map(
              r.prop(r.__, links),
            ),
            r.sortBy(
              r.pipe(
                r.prop('groupId'),
                ofIndex(order),
              ),
            ),
          ),
        ),
      ),
      // ensure all of the NONE_SETs are first
      r.sortBy(
        r.pipe(
          r.prop('options'),
          r.find(r.propEq('optionId', 'NONE_SET')),
          r.ifElse(
            Boolean,
            r.always(-1),
            r.always(0),
          ),
        ),
      ),
    );
    return buildVariantions(variations);
  },
);

// build a tree showing all of the possible combinations of options
// so you get Group -> Option -> Group -> Option -> ... -> VARIATIONS -> ID
interface Tree {
  [key: string]: Tree | any,
}

export const getOptionTree = createSelector(
  getVariations,
  (variations) => {
    const makeTheTree = r.pipe(
      r.map((variation: Variation) => {
        const tree: Tree = {};
        let current = tree;
        const updateTree = r.pipe(
          r.prop('options'),
          mapIndexed((option: VariantLink, i) => {
            const {
              groupId,
              optionId,
            } = option;

            current[groupId] = {};
            current[groupId][optionId] = {};
            current = r.path([ groupId, optionId ], current);

            if (r.equals(variation.options.length -1, i)) {
              current[VARIATIONS] = {};
              current[VARIATIONS][variation.id] = variation;
            }
          }),
        );
        updateTree(variation);

        return tree;
      }),
      r.reduce(r.mergeDeepRight, {}),
    );
    return makeTheTree(variations);
  },
);

export const getAddons = createSelector(
  getData,
  getIncludeTax,
  (data, includeTax) => r.pipe(
    r.prop('addons'),
    //@ts-ignore
    r.values,
    r.map(
      r.over(
        r.lensProp('priceAdjustment'),
        r.propOr(0, r.ifElse(
          r.equals(true),
          r.always('gross'),
          r.always('net'),
        )(includeTax)),
      ),
    ),
    // @ts-ignore
  )(data),
);

export const getValues = createSelector(
  getAllValues,
  getGroupOrder,
  (allValues, order) => {
    return r.pipe(
      sortObjBy(ofIndex(order)),
      rejectObjIndexed((v, k) => r.startsWith('addon.', k)),
    //@ts-ignore
    )(allValues);
  },
);

const getAddonValues = createSelector(
  getAllValues,
  filterObjIndexed((v, k) => r.startsWith('addon.', k)),
);

// gets all of the options and filters out those that are not available
// based on the user's current selection
export const getAvailableOptions = createSelector(
  getValues,
  getAllOptions,
  getOptionTree,
  getIncludeTax,
  (values: { [key: string]: any }, allOptions: Options, tree: Tree, includeTax: boolean) => {
    const result: Options = {};

    const addGroup = (tree: Tree) => {
      // productOption.OperatingSystem
      const key = getFirstKey(tree) || '';

      // CentOS7
      //@ts-ignore
      const value = r.prop(key, values);

      const addOptions = r.pipe(
        r.prop(key),
        r.defaultTo({}),
        // { productOption.Plesk }, CentOS7
        r.mapObjIndexed((children: Tree | Object, subKey: string) => {
          const id = `${key}.${subKey}`;
          const option = r.prop(id, allOptions);
          if (option) {
            result[id] = option;
          }

          if (value === subKey) {
            addGroup(children as any);
          }
        }),
      );

      addOptions(tree);

      return result;
    };

    const createGroups = r.pipe(
      addGroup,
      r.reject(r.isNil),
      r.map(
        r.over(
          r.lensProp('priceAdjustment'),
          r.propOr(0, r.ifElse(
            r.equals(true),
            r.always('gross'),
            r.always('net'),
          )(includeTax)),
        ),
      ),
    );

    return createGroups(tree);
  },
);

// gets all of the groups and associates the options with them
// uses getAvailableOptions so it will only give you valid options
export const getOptionGroups = createSelector<any, any, any>(
  [
    getAllGroups,
    getAvailableOptions,
  ],
  //@ts-ignore
  (groups: Groups, options: Options) => r.pipe(
    //@ts-ignore
    r.map(
      r.over(
        r.lensProp('options'),
        r.pipe(
          r.map(r.prop(r.__, options)),
          r.reject(r.isNil),
          r.sortBy(r.complement(r.propEq('optionId', 'NONE_SET'))),
        ),
      ),
    ),
    // @ts-ignore
    r.filter(
      r.pipe(
        r.prop('options'),
        //@ts-ignore
        r.length,
        r.gt(r.__, 0),
      ),
    ),
    // @ts-ignore
  )(groups),
);

export const getSelectedAddons = createSelector(
  getAddonValues,
  getAddons,
  (values, addons) => {
    return r.pipe(
      r.filter(
        r.pipe(
          r.prop('id'),
          //@ts-ignore
          r.prop(r.__, r.invertObj(values)),
        ),
      ),
      // @ts-ignore
    )(addons);
  },
);

export const getSelectedAddonIds = createSelector(
  //@ts-ignore
  getSelectedAddons,
  r.map(r.prop('id')),
);

// for each of the user's selected options, return the actual option object
export const getSelectedOptions = createSelector(
  getValues,
  getOptionGroups,
  (values: Object, groups: Array<Group>) => {
    return r.pipe(
      r.map(
        r.converge(
          r.find,
          [
            r.pipe(
              r.prop('groupId'),
              r.prop(r.__, values),
              r.propEq('optionId'),
            ),
            r.prop('options'),
          ],
        ),
      ),
      r.reject(r.isNil),
    )(groups);
  },
);

// goes down the left-most path of the option tree
// and returns an object containing alng { group: defaultValue }
// used to instantiate redux-form
const getDefaultValues = createSelector(
  getOptionTree,
  (tree: Tree) => {
    const result: { [key: string]: string } = {};
    let branch = tree;

    while (branch) {
      const key = getFirstKey(branch) || '';
      const value = getFirstKey(r.prop(key, branch));

      if (key === VARIATIONS) {
        branch = null;
      } else {
        result[key] = value;

        branch = r.path([ key, value ], branch);
      }
    }

    return result;
  },
);

const getInitialVariation = createSelector(
  getInitialSkuId,
  getVariations,
  (skuId, variations) => r.find(r.propEq('id', skuId), variations),
);

export const getInitialValues = createSelector(
  getInitialVariation,
  getDefaultValues,
  (variation, defaultValues) => {
    if (variation != null) {
      return r.pipe(
        r.map(
          r.pipe(
            r.pick([ 'groupId', 'optionId' ]),
            r.values,
          ),
        ),
        //@ts-ignore
        r.fromPairs,
        // @ts-ignore
      )(variation.options);
    }
    return defaultValues;
  },
);

export const getOptionSummary = createSelector(
  getSelectedOptions,
  r.map(
    r.pipe(
      r.defaultTo({}),
      (option: Option) => ({
        name: option.name,
        priceAdjustment: option.priceAdjustment,
        included: r.equals(0, option.priceAdjustment),
        currency: option.currency,
      }),
    ),
  ),
);

// adds together all of the price adjustments of the user's selected options
const getAdjustments = createSelector(
  getSelectedOptions,
  getSelectedAddons,
  r.converge(
    r.add,
    [
      r.pipe(
        r.map(r.prop('priceAdjustment')),
        r.map(r.defaultTo(0)),
        //@ts-ignore
        r.reduce(r.add, 0),
      ),
      r.pipe(
        r.nthArg(1),
        r.map(r.prop('priceAdjustment')),
        r.map(r.defaultTo(0)),
        //@ts-ignore
        r.reduce(r.add, 0),
      ),
    ],
  ),
);

export const getTotalPrice = createSelector(
  getBasePrice,
  getAdjustments,
  r.add,
);

// returns an array of groups that have invalid options
// so when the user picks an option, and the other options are affected by
// it, we can quickly see which groups need changing
export const getInvalidGroups = createSelector(
  getValues,
  getAvailableOptions,
  (values, options) => r.pipe(
    r.mapObjIndexed(
      r.pipe(
        r.unapply(r.identity),
        r.take(2),
        // @ts-ignore
        r.reverse,
        //@ts-ignore
        r.join('.'),
        r.prop(r.__, options),
      ),
    ),
    r.filter(r.isNil),
    r.keys,
  )(values),
);

// goes through the tree and follows the user's selected values
// to get the currently-selected variation
export const getVariation = createSelector(
  getOptionTree,
  getValues,
  (tree, values) => {
    return r.pipe(
      r.toPairs,
      // @ts-ignore
      r.reduce(
        r.flip(r.path),
        tree,
      ),
      r.prop(VARIATIONS),
      getFirstValue,
    )(values);
  },
) as any as (data: any) => Variation;

export const getCategoryName = createSelector(
  getData,
  getVariation,
  (data, variation) => {
    // Following PPE changes to remove additional shu info the category will be
    // returned on the product rather than each of the variations/additional sku
    // For now the UI needs to handle both cases
    const productCategory = r.path([ 'product', 'category' ], data);
    return productCategory ? productCategory : r.pathOr('', [ 'category' ], variation);
  },
);

export const getRenewalPeriod = createSelector(
  getData,
  getVariation,
  (data, variation) => {
    // Following PPE changes to remove additional shu info the term will be
    // returned on the product rather than each of the variations/additional sku
    // For now the UI needs to handle both cases
    const productTerm = r.path([ 'product', 'term' ], data);
    return productTerm ? productTerm : r.pathOr(1, [ 'term' ], variation);
  },
);

export const getPaymentInterval = createSelector(
  getData,
  getVariation,
  (data, variation) => {
    // Following PPE changes to remove additional shu info the paymentInterval will be
    // returned on the product rather than each of the variations/additional sku
    // For now the UI needs to handle both cases
    const productPaymentInterval = r.path([ 'product', 'paymentInterval' ], data);
    // eslint-disable-next-line max-len
    return productPaymentInterval ? productPaymentInterval : r.pathOr(1, [ 'paymentInterval' ], variation);
  },
);

const getCurrency = createSelector(
  [
    getData,
    getVariation,
  ],
  (data, variation) => {
    const productCurrency = r.path([ 'product', 'price', 'currency' ], data);
    const variant = r.prop('currency', variation);
    return productCurrency ? productCurrency : variant;
  },
);

type GetConfiguredProduct = (state: any) => {
  specs: {
    label: string,
    attribute: string,
    displayOrder: number,
    name: string,
    quantity: number,
    unit: string,
  }[],
  name: string,
  price: number,
  totalPrice: number,
  paymentInterval: number,
  options: {
    groupId: string,
    icon: string,
    id: string,
    meta?: {
      externalId: string,
      'os-distribution': string,
    },
    name: string,
    optionId: string,
    priceAdjustment: number,
    productId: number,
    currency: string,
  }[],
  addons?: {
    categoryName: string,
    groupId: string,
    icon: string,
    id: string,
    name: string,
    optionId: string,
    priceAdjustment: number,
    productId: number,
    currency: string,
  }[],
  category: string,
  currency: string,
};
export const getConfiguredProduct = createSelector(
  getProductName,
  getCategoryName,
  getBasePrice,
  getTotalPrice,
  getProductSpecs,
  getOptionSummary,
  getSelectedAddons,
  getRenewalPeriod,
  getPaymentInterval,
  getCurrency,
  r.unapply(
    r.zipObj([
      'name',
      'category',
      'price',
      'totalPrice',
      'specs',
      'options',
      'addons',
      'renewalPeriod',
      'paymentInterval',
      'currency',
    ]),
  ),
) as unknown as GetConfiguredProduct;

const getBrandLocale = createSelector(
  wlSelectors.getBrandConfig,
  r.pathOr('en', [ 'locale', 'default' ]),
);

export const getLocales = createSelector(
  wlSelectors.getLooseLocale,
  getBrandLocale,
  r.unapply(r.identity),
);

export const isMultiQuantityEnabled = createSelector(
  wlSelectors.getBrandConfig,
  r.pathOr(false, [ 'basket', 'multiQuantity' ]),
);

export const getQuantity = createSelector(
  getFormValues('usf.configurator'),
  r.propOr(1, 'quantity'),
) as any as (state: any) => number;
