import React from 'react';
import ReactDOM from 'react-dom';

const render = (Component: any) => (props: any) => {
  ReactDOM.render(
    (
      <Component
        {...props}
      />
    ),
    document.getElementById('root')
  );
};

export default render;
