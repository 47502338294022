import * as r from 'ramda';
import { createSelector } from 'reselect';
import { OptionSchemaResponse } from '.';

// So what the heck is going on here? Well basically our variation options
// used to contain the key/value (i.e. attributeName and attributeValue), which
// meant we could easily map between the options and the variations.
// However, now we just get some ids, which means we have to cross reference
// the variation option ids with the option value ids, which isnt easy to do
// at transformation-time because the two data sets live in completely different
// (nested) locations.
// Therefore we have to reach a point where we have access to both
// options.allowedValues[n].productOptionValueId
// as well as variations[n].productOptionValues[n].productOptionValueId
// then pluck the attributeName out of one and insert it into the other.

const getAllVariationOptions = createSelector(
  r.identity,
  r.reduce(
    r.converge(
      r.concat,
      [
        r.pipe(
          r.nthArg(1),
          r.converge(
            r.map,
            [
              r.pipe(
                r.prop('attributeName'),
                //@ts-ignore
                r.assoc('attributeName'),
              ),
              r.prop('allowedValues'),
            ],
          ),
        ),
        r.nthArg(0),
      ],
    ),
    [],
  ),
);

export const getVariationOptions = createSelector(
  getAllVariationOptions,
  r.nthArg(1),
  (allOptions, options) => r.map(
    r.converge(
      (fullOption, option) => r.pipe(
        r.assoc('attributeName', r.prop('attributeName', fullOption)),
        // PPE changes are being made to reduce the data returned in variations,
        // when this happens we will also need to map attibuteValue and priceAdjusment
        // though there is no harm in doing this in advance
        // (label appears to be unused so is not mapped here)
        r.assoc('attributeValue', r.prop('attributeValue', fullOption)),
        r.assoc('priceAdjustment', r.prop('priceAdjustment', fullOption))
      )(option),
      [
        r.curryN(1,
          r.pipe(
            r.curryN(2,
              r.both(
                r.eqProps('productOptionId'),
                r.eqProps('productOptionValueId'),
              ),
            ),
            r.flip(r.find)(allOptions),
          ),
        ),
        r.identity,
      ],
    ),
  )(options),
);

const iconMatrix = {
  'os-distribution': {
    ubuntu: 'ubuntu',
    centos: 'centos',
    debian: 'debian',
  },
  'os-type': {
    linux: 'linux',
    windows: 'windows',
  },
  'software-type': {
    cpanel: 'cpanel',
    plesk: 'plesk',
  },
  'hardware-type': {
    cpu: 'cpu',
    gpu: 'gpu',
    memory: 'ram',
  },
  'storage-type': {
    database: 'database',
    hdd: 'hdd',
    raid: 'hdd',
  },
  'kind': {
    'storage': 'hdd',
    'server': 'servers',
    'network': 'nodes',
    'security': 'padlock',
    'operating-system': 'padlock',
    'software': 'code',
    'hardware': 'cpu',
  },
} as {
  [key: string]: any,
};

export const getIcon = (data: OptionSchemaResponse) => {
  return r.pipe(
    r.keys,
    r.reduce((result: any, key: string) => {
      if (result) {
        return result;
      }
      const options = iconMatrix[key] as {
        [key: string]: any,
      };
      const a = r.path([ 'metadata', key ], data) as string;
      const b = r.path([ 'parentMeta', key ], data) as string;
      return options[a] || options[b];
    }, null),
    r.defaultTo('tick'),
  )(iconMatrix);
};
