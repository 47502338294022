import _fetch from './fetch';
import { Middleware } from '@ux/drivers';

export default (
  middleware: Middleware,
) => {
  return {
    fetch: _fetch(middleware),
  };
};
