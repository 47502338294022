import { ApplyVoucher } from 'core/basket';
import * as r from 'ramda';
import { Middleware } from '@ux/drivers';

const applyVoucher = (
  middleware: Middleware,
): ApplyVoucher => (
  brandId,
  voucherCode,
) => r.pipe(
  r.assoc('brandId', brandId),
  r.assoc('code', voucherCode),
  (data) => middleware(0, 'redeemVoucher', data),
)({});

export default applyVoucher;
