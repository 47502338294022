import { ApplyVoucher as ApplyVoucherService } from 'core/basket';

import { createEpic } from '@ux/fabric';
import {
  signals as rkSignals,
} from '@ux/reactive-knowledge';
import { combineEpics } from 'redux-most';
import * as most from 'most';
import * as r from 'ramda';

import {
  APPLY_VOUCHER,
  ApplyVoucher,
} from 'application/signals/basket';
import {
  VOUCHER_APPLIED,
  VOUCHER_FAILED,
  VoucherFailed,
  applyingVoucher,
  voucherApplied,
  voucherFailed,
  voucherReset,
} from 'domain/messages/basket';
import { getBrandId } from 'domain/selectors/common';

const applyVoucherEpic = (
  apply: ApplyVoucherService,
) => createEpic({
  signal: APPLY_VOUCHER,
  pending: r.pipe(
    r.prop('payload'),
    r.pick([ 'buttonId', 'voucherCode' ]),
    r.values,
    r.apply(applyingVoucher),
  ),
  process: (action: ApplyVoucher, state: any) => {
    const brandId = getBrandId(state);
    return apply(brandId, action.payload.voucherCode);

  },
  onSuccess: r.pipe(
    r.nthArg(1),
    r.prop('payload'),
    r.pick([ 'buttonId', 'voucherCode', 'postAction' ]),
    r.values,
    r.apply(voucherApplied),
  ),
  onFailure: (err: Error, action: ApplyVoucher) => voucherFailed(
    err,
    action.payload.buttonId,
    action.payload.voucherCode,
  ),
});

const successEpic = () => createEpic({
  signal: VOUCHER_APPLIED,
  onSuccess: r.always(rkSignals.add({
    type: 'SMIRK',
    message: 'usf.applyVoucher.success',
  })),
});

const failedEpic = () => createEpic({
  signal: VOUCHER_FAILED,
  onSuccess: r.always(rkSignals.add({
    type: 'ERK',
    message: 'usf.applyVoucher.failure',
  })),
});

const resetEpic = () => createEpic({
  signal: VOUCHER_FAILED,
  intercept: (actions$) => most.delay(2000, actions$),
  onSuccess: (x, action: VoucherFailed) => {
    const buttonId = action.meta.buttonId;
    const voucherCode = action.meta.voucherCode;
    return voucherReset(buttonId, voucherCode);
  },
});

export default (applyVoucher: ApplyVoucherService) => combineEpics([
  applyVoucherEpic(applyVoucher),
  successEpic(),
  failedEpic(),
  resetEpic(),
]);
