import { Colorway } from '@ux/theme-utils';

export {
  Colorway,
  Colorway as Against,
};

export enum SafeColorway {
  LIGHT = 'LIGHT',
  NEUTRAL = 'NEUTRAL',
  DARK = 'DARK',
}

export enum UnsafeColorway {
  //...SafeColorway,
  LIGHT = 'LIGHT',
  NEUTRAL = 'NEUTRAL',
  DARK = 'DARK',
  PRIMARY = 'PRIMARY',
  PRIMARY_GRADIENT = 'PRIMARY_GRADIENT',
  HIGHLIGHT = 'HIGHLIGHT',
}
