import { LOCATION_CHANGE } from 'connected-react-router';
import { createEpic } from '@ux/fabric';
import * as r from 'ramda';
import { SendFBAnalytics } from '@ux/tagmg';
import {
  pageChanged,
} from 'domain/messages/root';
import {
  getCurrentPage,
} from 'domain/selectors/root';

const getPage = r.path([ 'payload', 'location', 'pathname' ]);

const pageChangedEpic = (dispatchFBEvent: SendFBAnalytics) => createEpic({
  signal: LOCATION_CHANGE,
  filter: (action, state) => {
    const previous = getCurrentPage(state);
    const next = getPage(action);
    if (previous !== next) {
      dispatchFBEvent('ViewContent', {
        //eslint-disable-next-line camelcase
        content_name: next,
      });
    }
    return previous !== next;
  },
  onSuccess: r.pipe(
    getPage,
    pageChanged,
  ),
});

export default pageChangedEpic;
