// In IE11 (what else) the Symbol polyfill doesn't play nicely with dynamic imports
// When a module is asynchronously loaded, webpack attempts to set the `module.toString()`
// property via a symbol (Symbol.toStringTag) and for some reason this falls over
// So we monkey patch Object.defineProperty and if toStringTag is falling over, we fail
// silently
const dp = Object.defineProperty;
Object.defineProperty = (obj: object, key: string, descriptor: string) => {
  try {
    dp(obj, key, descriptor);
  } catch (e) {
    //@ts-ignore
    if (key === Symbol.toStringTag) {
      return obj;
    }
    throw e;
  }
};
