import { Theme } from '@ux/theme-utils';
import { Colorway } from './colorway';
import { Layout } from './layout';

export interface RibbonProps {
  colorway: Colorway,
  theme: Theme,
  name: string,
  layout: Layout,
  loading: boolean,
  hBaseline?: number,
  id: string,
  flush?: Flush|boolean,
  duoLayout?: boolean,
  visible?: boolean,
}

export enum Type {
  SKIPPED = 'RIBBON_SKIPPED',
  ADS = 'Ads',
  ADVOCATE_TRUST_PILOT = 'AdvocateTrustPilot',
  AGGREGATE_RATING_TRUST_PILOT = 'AggregateRatingTrustPilot',
  AKLAMIO_REFERRAL_PORTAL = 'AklamioReferralPortal',
  ASPECTS = 'Aspects',
  BILLBOARD_GENERIC = 'BillboardGeneric',
  BILLBOARD_PRODUCT = 'BillboardProduct',
  BILLBOARD_DOMAIN = 'BillboardDomain',
  BROADCAST = 'Broadcast',
  CALLBACK = 'Callback',
  CENTERFOLD = 'Centerfold',
  CHANNELS = 'Channels',
  CUSTOMER_CASE_STUDY = 'CustomerCaseStudy',
  DOMAIN_SEARCH = 'DomainSearch',
  DOMAIN_SEARCH_START = 'DomainSearchStart',
  DOMAIN_SEARCH_START_STRIP = 'DomainSearchStartStrip',
  DOMAIN_PROMO = 'DomainPromo',
  ELABORATE = 'Elaborate',
  INTRO = 'Intro',
  FILMSTRIP = 'Filmstrip',
  FREE_SPEECH = 'FreeSpeech',
  INLINE_PRIVACY_MANAGER = 'InlinePrivacyManager',
  LEGAL = 'Legal',
  LEGAL_DOCUMENT = 'LegalDocument',
  MESSAGE_STRIP = 'MessageStrip',
  META = 'Meta',
  MULTI_COMPARISON = 'MultiComparison',
  PILLARS = 'Pillars',
  PILLARS_RECUMBENT = 'PillarsRecumbent',
  POSTER = 'Poster',
  PRODUCT_CARDS = 'ProductCards',
  SALESBOARD_CLASSIC = 'SalesboardClassic',
  SHOWCASE = 'Showcase',
  TABLE = 'Table',
  TERMINATE_FOLD = 'TerminateFold',
  TIERS = 'Tiers',
  WEBSITE_REPORT_START = 'WebsiteReportStart',
}

export enum Fit {
  FULLSCREEN = 'fullscreen',
  FILL = 'fill',
  AUTO = 'auto',
}

export enum FetchType {
  PAGE_LANGUAGE = 'PAGE_LANGUAGE',
  RIBBONS_PREFETCHED = 'RIBBONS_PREFETCHED',
  RIBBON_FETCHED = 'RIBBON_FETCHED',
  RIBBONS_FETCHED = 'RIBBONS_FETCHED',
  ERRORED = 'ERRORED',
}

export enum Flush {
  LEADING = 'LEADING',
  TRAILING = 'TRAILING',
}
